/** @jsxImportSource @emotion/react */
import { Card, Layout, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import { css } from "@emotion/react";
import { Col, Container, Row } from "react-bootstrap";
import { TeacherFindOneResponse } from "../../types/teacher";
import { useAuth } from "../../context/auth-context";
import { useRecoilValue } from "recoil";
import { classroomsState } from "../../states/classrooms";
import {
  currentUserNameWithGreetingState,
  currentUserState,
} from "../../states/auth";
import ClassroomListComponent from "./classroom-list";
import { Student, StudentStatusType } from "../../types/student";

const TeacherHomeComponent = () => {
  const navigate = useNavigate();
  const { fetchWithToken } = useAuth();

  const [loading, setLoading] = useState(false);
  const classrooms = useRecoilValue(classroomsState);
  const currentUser = useRecoilValue(currentUserState);
  const [presentStudents, setPresentStudents] = useState<
    Map<number, Student[]>
  >(new Map());
  const [teacher, setTeacher] = useState<TeacherFindOneResponse | undefined>();

  useEffect(() => {
    const fetchTeacher = async () => {
      const response = (await fetchWithToken({
        url: `/teacher/${currentUser!.id}`,
        method: "GET",
      })) as TeacherFindOneResponse | undefined;

      if (response) {
        setTeacher(response);
      }
    };

    const handleLoading = async () => {
      try {
        setLoading(true);

        await Promise.all([fetchTeacher()]);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    if (currentUser) {
      handleLoading();
    }
  }, [fetchWithToken, currentUser]);

  useEffect(() => {
    const updatedPresentStudents = new Map<number, Student[]>();

    for (const classroom of classrooms) {
      const presentStudents = classroom.students.filter(
        (student) => student.status === StudentStatusType.CHECKED_IN
      );

      updatedPresentStudents.set(classroom.id, presentStudents);
    }

    setPresentStudents(updatedPresentStudents);
  }, [classrooms]);

  return (
    <Layout style={{ margin: 16, backgroundColor: "transparent" }}>
      <Content
        style={{
          margin: "24px 16px 0",
          overflow: "initial",
        }}
      >
        <Container fluid>
          <Row
            css={css`
              gap: 8px;
            `}
          >
            <ClassroomListComponent
              classrooms={classrooms}
              presentStudents={presentStudents}
            />
          </Row>
        </Container>
      </Content>
    </Layout>
  );
};

export default TeacherHomeComponent;
