import { Event, EventSummary } from "./event";
import { Parent } from "./parent";

export enum StudentStatusType {
  CHECKED_IN = "CHECKED_IN",
  CHECKED_OUT = "CHECKED_OUT",
  NOT_PRESENT = "NOT_PRESENT",
  ABSENT = "ABSENT",
}

export interface Student {
  id: number;
  firstName: string;
  lastName: string;
  schoolId: number;
  classroomId: number;
  status: StudentStatusType;
  lastAttendanceEvent?: Event;
}

export interface StudentGetDetailsResponse {
  student: Student;
  parents: Parent[];
}
export interface StudentGetEventsResponse {
  events: Event[];
  eventSummaries: EventSummary[];
}
