import { selector } from "recoil";
import { Student } from "../types/student";
import { classroomsState } from "./classrooms";
import { accessTokenState, userRoleState } from "./auth";
import { UserRoleType } from "../types/user";
import { utilFetchWithToken } from "../util";
import { StudentsWithClassroomsResponse } from "../types/parent";

export const studentsState = selector<Student[]>({
  key: "studentsState",
  get: async ({ get }) => {
    const classrooms = get(classroomsState);
    return classrooms.flatMap((classroom) => classroom.students);
  },
});

export const parentsStudentsState = selector<
  StudentsWithClassroomsResponse["students"]
>({
  key: "parentsStudentsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const userRole = get(userRoleState);

    if (accessToken && userRole?.type === UserRoleType.PARENT) {
      const response = (await utilFetchWithToken({
        accessToken,
        url: `/parent/students`,
        method: "GET",
      })) as StudentsWithClassroomsResponse | undefined;

      return response?.students ?? [];
    }

    return [];
  },
});
