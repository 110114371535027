/** @jsxImportSource @emotion/react */
import { Alert, Button, Flex, Form, Input } from "antd";
import { useState } from "react";
import { Card, Container } from "react-bootstrap";
import styled from "styled-components";
import { API_URL, setAuthCookie } from "../../util";
import { useNavigate, useParams } from "react-router-dom";
import {
  UserAcceptInviteErrorType,
  UserAcceptInviteResponse,
} from "../../types/user";
import { accessTokenState } from "../../states/auth";
import { useRecoilState } from "recoil";

const StyledFlexContainer = styled(Flex)`
  height: 100vh;
`;

type FormFieldType = {
  password?: string;
  confirmPassword?: string;
};

const AcceptInviteComponent = () => {
  const [, setAccessToken] = useRecoilState(accessTokenState);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { inviteToken } = useParams();
  const [error, setError] = useState<UserAcceptInviteErrorType | undefined>();

  const handleAcceptInvite = async (values: FormFieldType) => {
    const { password } = values;

    try {
      setError(undefined);
      setLoading(true);

      const acceptInviteResponse = await fetch(`${API_URL}/accept-invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ inviteCode: inviteToken, password }),
      });

      const response =
        (await acceptInviteResponse.json()) as UserAcceptInviteResponse;

      if (response.accessToken) {
        setAccessToken(response.accessToken);
        setAuthCookie(response.accessToken);
        navigate("/");
      }

      if (acceptInviteResponse.status === 404) {
        // TODO: Change this once api handlers 404 errors
        setError(UserAcceptInviteErrorType.INVALID_TOKEN);
      }

      if (!response.acceptInviteSuccess && response.acceptInviteError) {
        setError(response.acceptInviteError);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <Container>
      <StyledFlexContainer justify="center" align="center" vertical>
        {error === UserAcceptInviteErrorType.INVALID_TOKEN && (
          <Alert
            message="Your invite token is invalid. Please check your invite link or contact the individual who invited you."
            type="error"
            style={{ margin: 20 }}
          />
        )}
        {error === UserAcceptInviteErrorType.INVITE_ALREADY_ACCEPTED && (
          <Alert
            message="This invite has already been accepted. Sign in instead."
            type="warning"
            action={
              <Button onClick={() => navigate("/sign-in")}>Sign In</Button>
            }
            style={{ margin: 20 }}
          />
        )}
        <Card style={{ padding: 20 }}>
          <Card.Title>Accept Invite</Card.Title>
          <Card.Subtitle>
            You've been invited to use Clear Day. Set a password below to
            complete your account creation.
          </Card.Subtitle>
          <Flex justify="center">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              style={{ maxWidth: 600, minWidth: 400, marginTop: 20 }}
              onFinish={(values: FormFieldType) => handleAcceptInvite(values)}
              onFinishFailed={(error) => console.log(error)}
              autoComplete="off"
            >
              <Form.Item<FormFieldType>
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password should be at least 8 characters long",
                    min: 8,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item<FormFieldType>
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Accept Invite
              </Button>
            </Form>
          </Flex>
        </Card>
      </StyledFlexContainer>
    </Container>
  );
};

export default AcceptInviteComponent;
