/** @jsxImportSource @emotion/react */
import React from "react";
import { AuthContextProvider } from "../../context/auth-context";
import AuthenticatedIndexComponent from "./authenticated-index";

const AuthenticatedWrapperComponent: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <AuthContextProvider>
      <AuthenticatedIndexComponent>{children}</AuthenticatedIndexComponent>
    </AuthContextProvider>
  );
};

export default AuthenticatedWrapperComponent;
