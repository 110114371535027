/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Student, StudentStatusType } from "../../types/student";
import { Flex, Popover, Tooltip } from "antd";
import { useState } from "react";
import { useAuth } from "../../context/auth-context";
import { TIMELINE_ICONS } from "../student";
import { EventType } from "../../types/event";
import ButtonTag from "./button-tag";
import styled from "styled-components";
import SpinnerComponent from "../common/spinner";

const BUTTON_TEXT = {
  [StudentStatusType.CHECKED_IN]: "Present",
  [StudentStatusType.CHECKED_OUT]: "Checked Out",
  [StudentStatusType.NOT_PRESENT]: "Not Present",
  [StudentStatusType.ABSENT]: "Absent",
};

const COLORS = {
  [StudentStatusType.CHECKED_IN]: "green",
  [StudentStatusType.CHECKED_OUT]: "blue",
  [StudentStatusType.NOT_PRESENT]: "red",
  [StudentStatusType.ABSENT]: "purple",
};

const ButtonTextContainer = styled.span`
  margin-left: 4px;
`;

const AttendanceTag = ({
  student,
  handleOnStatusChange,
}: {
  student: Student;
  handleOnStatusChange(updatedStudent: Student): Promise<void>;
}) => {
  const { fetchWithToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleChangeStudentStatus = async (
    student: Student,
    updatedStatus: StudentStatusType
  ) => {
    try {
      setLoading(true);

      const changeStudentStatusResponse = (await fetchWithToken({
        url: `/student/${student.id}/attendanceStatus`,
        method: "POST",
        body: { status: updatedStatus },
      })) as Student | undefined;

      if (changeStudentStatusResponse) {
        handleOnStatusChange(changeStudentStatusResponse);
        setPopoverOpen(false);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const isPresent = student.status === StudentStatusType.CHECKED_IN;

  let tagIcon = TIMELINE_ICONS[EventType.CHECKED_OUT];

  if (student.status === StudentStatusType.CHECKED_IN) {
    tagIcon = TIMELINE_ICONS[EventType.CHECKED_IN];
  } else if (student.status === StudentStatusType.CHECKED_OUT) {
    tagIcon = TIMELINE_ICONS[EventType.CHECKED_OUT];
  } else if (student.status === StudentStatusType.ABSENT) {
    tagIcon = TIMELINE_ICONS[EventType.MARKED_ABSENT];
  }

  const lastEventText =
    student.lastAttendanceEvent &&
    student.status !== StudentStatusType.NOT_PRESENT
      ? `@ ${new Date(
          student.lastAttendanceEvent.createdAt
        ).toLocaleTimeString()}`
      : "";

  return (
    <div
      css={css`
        cursor: pointer;
      `}
    >
      <Tooltip title="Change status">
        <Popover
          title="Change status"
          open={popoverOpen}
          onOpenChange={(visible) => setPopoverOpen(visible)}
          content={
            <SpinnerComponent spinning={loading}>
              <Flex
                vertical
                gap={16}
                align="center"
                justify="center"
                style={{ padding: 6 }}
              >
                {isPresent && (
                  <ButtonTag
                    color={COLORS[StudentStatusType.CHECKED_OUT]}
                    onClick={() =>
                      handleChangeStudentStatus(
                        student,
                        StudentStatusType.CHECKED_OUT
                      )
                    }
                  >
                    <ButtonTextContainer>Check Out</ButtonTextContainer>
                  </ButtonTag>
                )}

                {!isPresent && (
                  <ButtonTag
                    color={COLORS[StudentStatusType.CHECKED_IN]}
                    onClick={() =>
                      handleChangeStudentStatus(
                        student,
                        StudentStatusType.CHECKED_IN
                      )
                    }
                  >
                    <ButtonTextContainer>Check In</ButtonTextContainer>
                  </ButtonTag>
                )}
                {!isPresent && student.status !== StudentStatusType.ABSENT && (
                  <ButtonTag
                    color={COLORS[StudentStatusType.ABSENT]}
                    onClick={() =>
                      handleChangeStudentStatus(
                        student,
                        StudentStatusType.ABSENT
                      )
                    }
                  >
                    <ButtonTextContainer>Mark Absent</ButtonTextContainer>
                  </ButtonTag>
                )}
              </Flex>
            </SpinnerComponent>
          }
          trigger="click"
        >
          <ButtonTag color={COLORS[student.status]}>
            <ButtonTextContainer>
              {BUTTON_TEXT[student.status]} {lastEventText}
            </ButtonTextContainer>
          </ButtonTag>
        </Popover>
      </Tooltip>
    </div>
  );
};

export default AttendanceTag;
