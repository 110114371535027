import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import Classroom from "./components/classroom";
import { createGlobalStyle } from "styled-components";
import AuthenticatedAppWrapper from "./components/app/authenticated-wrapper";
import Student from "./components/student";
import { ConfigProvider } from "antd";
import Home from "./components/home";
import SignUpComponent from "./components/app/sign-up";
import { RecoilRoot } from "recoil";
import SignInComponent from "./components/app/sign-in";
import ForgotPasswordComponent from "./components/app/forgot-password";
import ConfirmPasswordResetComponent from "./components/app/confirm-password-reset";

import "bootstrap/dist/css/bootstrap.min.css";
import AcceptInviteComponent from "./components/app/accept-invite";
import OnboardingComponent from "./components/onboarding";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import UserSettingsComponent from "./components/settings/user";
import AiToolsHome from "./components/co-teacher";
import PublishedContentList from "./components/co-teacher/list-published-content";
import CreateEditPublishedContent from "./components/co-teacher/create-edit-published-content";
import ViewPublishedContent from "./components/co-teacher/view-published-content";
import SunnyChat from "./components/co-teacher/sunny-chat";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

posthog.init(process.env.REACT_APP_POSTHOG_KEY ?? "", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only",
});

const GlobalStyle = createGlobalStyle`

  body,  {
    background-color: #fffaee;
    font-family: 'filson-pro', sans-serif !important;
    color: rgb(68, 68, 68) !important;
    
    
    h1, h2, h3, h4, h5, .ant-card-meta-title, .ant-menu-item, .card-title {
      font-family: 'p22-mackinac-pro', serif !important;
    }

    .card-title {
      margin-bottom: 24px;
    }

    .ant-menu-item {
      font-weight: 500;
    }

    .ant-popover-title {
      min-width: 80px !important;
      text-align: center;
    }

    .ant-card-bordered {
      border: 2px solid #ccccff;
    }
  }
`;

const router = createBrowserRouter([
  {
    element: (
      <AuthenticatedAppWrapper>
        <Outlet />
      </AuthenticatedAppWrapper>
    ),
    children: [
      {
        path: "/",
        element: <Home />, // TODO: Make use of the fetch functionality?
      },
      {
        path: "/onboarding/:step",
        element: <OnboardingComponent />,
      },
      {
        path: "/classroom/:classroomId",
        element: <Classroom />,
      },
      {
        path: "/student/:studentId",
        element: <Student />,
      },
      {
        path: "/classroom/:classroomId/student/:studentId",
        element: <Student />,
      },
      {
        path: "/settings/user",
        element: <UserSettingsComponent />,
      },
      {
        path: "/tools",
        element: <AiToolsHome />,
      },
      {
        path: "/tools/chat",
        element: <SunnyChat />,
      },
      {
        path: "/tools/:lowerCaseChatSessionType",
        element: <PublishedContentList />,
      },
      {
        path: "/tools/:lowerCaseChatSessionType/new",
        element: <CreateEditPublishedContent />,
      },
      {
        path: "/tools/:lowerCaseChatSessionType/:publishedContentId/edit",
        element: <CreateEditPublishedContent />,
      },
      {
        path: "/tools/:lowerCaseChatSessionType/:publishedContentId",
        element: <ViewPublishedContent />,
      },
    ],
  },
  {
    children: [
      { path: "/sign-up", element: <SignUpComponent /> },
      { path: "/sign-in", element: <SignInComponent /> },
      { path: "/forgot-password", element: <ForgotPasswordComponent /> },
      {
        path: "/confirm-password-reset/:passwordResetToken",
        element: <ConfirmPasswordResetComponent />,
      },
      {
        path: "/accept-invite/:inviteToken",
        element: <AcceptInviteComponent />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Suspense>
      <RecoilRoot>
        <GlobalStyle />
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "filson-pro, sans-serif",
            },
          }}
        >
          <RouterProvider router={router} />
        </ConfigProvider>
      </RecoilRoot>
    </Suspense>
  </React.StrictMode>
);

reportWebVitals();
