/** @jsxImportSource @emotion/react */
import { Tag, TagProps } from "antd";
import styled from "styled-components";

const StyledTag = styled(Tag)`
  cursor: pointer;
  transition: background-color 0.4s;
  text-align: center;
  padding: 3px 12px;
  margin-inline-end: 0;
  width: 100%;

  &.ant-tag-green {
    &:hover {
      background-color: #b7eb8f;
    }
  }

  &.ant-tag-red {
    &:hover {
      background-color: #ffa39e;
    }
  }

  &.ant-tag-purple {
    &:hover {
      background-color: #d3adf7;
    }
  }

  &.ant-tag-blue {
    &:hover {
      background-color: #91caff;
    }
  }
`;

const ButtonTag = (props: TagProps) => {
  return <StyledTag {...props} />;
};

export default ButtonTag;
