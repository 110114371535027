/** @jsxImportSource @emotion/react */
import React, { useState, KeyboardEvent } from "react";
import {
  Alert,
  Button,
  Card,
  Flex,
  Image,
  Input,
  message,
  Modal,
  Typography,
} from "antd";
import styled from "styled-components";
import { useAuth } from "../../../context/auth-context";
import { ChatRequest } from "../../../types/chat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePen,
  faFloppyDisk,
  faPencil,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  ChatSessionMessage,
  ChatSessionType,
} from "../../../types/chat-session";
import {
  PublishedContent,
  PublishedContentColoringSheetCreateRequest,
  PublishedContentCreateRequest,
} from "../../../types/published-content";
import SpinnerComponent from "../../common/spinner";
import Divider from "../../common/divider";

const Container = styled.div`
  width: 100%;
`;

const ChatContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 16px;
  }
`;

const InputContainer = styled.div`
  flex: 1;
`;

const ResponseContainerWrapper = styled.div`
  flex: 1;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

const ResponseContainer = styled.div`
  padding: 16px;
  background-color: #f0f2f5;
  border-radius: 4px;
  white-space: pre-wrap;
`;

const REQUEST_URL = {
  [ChatSessionType.ACTIVITY]: "activity",
  [ChatSessionType.CHAT]: "",
  [ChatSessionType.STUDENT_EVENT]: "student-event",
  [ChatSessionType.STORY]: "story",
  [ChatSessionType.SONG]: "song",
  [ChatSessionType.COLORING_SHEET]: "coloring-sheet",
};

export interface ChatInterfaceProps {
  title: string;
  subtitle: string;
  type: ChatSessionType;
  buttonTitle: string;
  titlePlaceholderText?: string;
  sessionId: number;
  sessionMessageHistory: ChatSessionMessage[];
  handleRefreshSessionHistory: () => void;
  publishedContent?: PublishedContent;
}

const TextInterface: React.FC<ChatInterfaceProps> = ({
  title,
  subtitle,
  type,
  buttonTitle,
  titlePlaceholderText,
  sessionId,
  sessionMessageHistory,
  handleRefreshSessionHistory,
  publishedContent,
}) => {
  const navigate = useNavigate();
  const { fetchWithToken } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();
  const [requestLoading, setRequestLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [requestText, setRequestText] = useState("");
  // Used for streaming text responses
  const [responseText, setResponseText] = useState("");
  // Used for responses with attachments
  const [responseUrl, setResponseUrl] = useState("");
  const [responseAttachmentId, setResponseAttachmentId] = useState<
    number | undefined
  >();

  const [error, setError] = useState(false);
  const [editing, setEditing] = useState(false);

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [contentTitle, setContentTitle] = useState("");

  const generateContent = async (requestText: string) => {
    try {
      setRequestLoading(true);
      setResponseText("");
      setError(false);

      if (!sessionId) {
        messageApi.error({
          content: "No session found. Please refresh the page",
        });
        return;
      }

      messageApi.open({
        type: "loading",
        content: "Working our magic...",
        key: "loadingMessage",
        duration: 0,
      });

      const requestBody: ChatRequest = {
        requestText,
        sessionId,
      };

      const response = await fetchWithToken({
        url: `/chat/${REQUEST_URL[type]}`,
        method: "POST",
        body: requestBody,
        stream: type !== ChatSessionType.COLORING_SHEET,
      });

      if (type !== ChatSessionType.COLORING_SHEET) {
        if (response.body) {
          const reader = response.body.getReader();
          let chunks = "";

          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }
            chunks += new TextDecoder("utf-8").decode(value);
            setResponseText(chunks);
          }
        }

        setRequestText("");
      } else {
        setResponseUrl(response.imageUrl);
        setResponseAttachmentId(response.attachmentId);
      }
      handleRefreshSessionHistory();
    } catch (error) {
      messageApi.error({ content: "Error creating" });
      console.error(error);
      setError(true);
    }

    setRequestLoading(false);
    setRequestText("");

    messageApi.destroy("loadingMessage");
  };

  const saveContent = async (updateExisting?: boolean) => {
    if (contentTitle === "" && !updateExisting) {
      messageApi.error({ content: "A title is required" });
      return;
    }

    if (!sessionId) {
      messageApi.error({
        content: "No session found. Please refresh the page",
      });
      return;
    }

    messageApi.open({
      type: "loading",
      content: "Working our magic...",
      key: "loadingMessage",
      duration: 0,
    });

    try {
      setSaveModalOpen(false);
      setSaveLoading(true);

      const requestBody: PublishedContentCreateRequest = {
        title: contentTitle,
        text: responseText,
        type,
        sessionId,
      };

      let saveContentResponse: PublishedContent | undefined;

      if (!updateExisting || !publishedContent) {
        saveContentResponse = (await fetchWithToken({
          url: `/published-content`,
          method: "POST",
          body: {
            ...requestBody,
            text: responseText !== "" ? responseText : publishedContent?.text,
          },
        })) as PublishedContent | undefined;
      }

      if (updateExisting && publishedContent) {
        saveContentResponse = (await fetchWithToken({
          url: `/published-content/${publishedContent.id}`,
          method: "PUT",
          body: {
            text: requestBody.text,
          },
        })) as PublishedContent | undefined;
      }

      if (saveContentResponse) {
        messageApi.open({
          type: "success",
          content: "Saved successfully",
        });

        navigate(`/tools/${REQUEST_URL[type]}`);
      }
    } catch (error) {
      messageApi.error({ content: "Error saving" });
      console.log(error);
    }

    messageApi.destroy("loadingMessage");
    setSaveLoading(false);
  };

  const saveColoringSheet = async () => {
    if (responseAttachmentId === undefined) {
      messageApi.error({ content: "No image found" });
      return;
    }

    if (!sessionId) {
      messageApi.error({
        content: "No session found. Please refresh the page",
      });
      return;
    }

    messageApi.open({
      type: "loading",
      content: "Working our magic...",
      key: "loadingMessage",
      duration: 0,
    });

    try {
      const requestBody: PublishedContentColoringSheetCreateRequest = {
        attachmentId: responseAttachmentId,
        sessionId,
        title: contentTitle,
      };

      const saveContentResponse = await fetchWithToken({
        url: `/published-content/coloring-sheet`,
        method: "POST",
        body: requestBody,
      });

      if (saveContentResponse) {
        messageApi.open({
          type: "success",
          content: "Saved successfully",
        });

        navigate(`/tools/${REQUEST_URL[type]}`);
      }
    } catch (error) {
      messageApi.error({ content: "Error saving" });
      console.log(error);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (requestText.trim()) {
        generateContent(requestText);
      }
    }
  };

  const handlePrint = () => {
    if (responseUrl) {
      const printWindow = window.open(responseUrl, "_blank");
      if (printWindow) {
        printWindow.onload = () => {
          printWindow.print();
        };
      }
    }
  };

  const responseTextToShow =
    responseText !== "" ? responseText : publishedContent?.text;

  return (
    <Container>
      {contextHolder}
      <Flex vertical gap={16} align="start">
        <Typography.Title level={4} style={{ marginBottom: 0 }}>
          {title}
        </Typography.Title>
        <Typography.Text>{subtitle}</Typography.Text>
        <ChatContainer>
          <InputContainer>
            <Card>
              {error && (
                <Alert
                  message="We couldn't process your request. Please try again."
                  type="error"
                  showIcon
                  style={{ marginBottom: 16 }}
                />
              )}
              {sessionMessageHistory.length === 0 && (
                <>
                  <Typography.Title level={5}>
                    Tell us what you want to create
                  </Typography.Title>
                  <Typography.Paragraph>
                    We'll use this to generate your {buttonTitle.toLowerCase()}
                  </Typography.Paragraph>
                  <Input.TextArea
                    rows={6}
                    onChange={(event) => setRequestText(event.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Tell us what to make and hit enter when you're done"
                  />
                </>
              )}
              {sessionMessageHistory.length > 0 && (
                <Flex vertical gap={16}>
                  {sessionMessageHistory.map((history, index) => (
                    <>
                      {index === 0 && (
                        <Flex vertical>
                          <Typography.Title level={5}>
                            Original Request
                          </Typography.Title>
                          <Typography.Paragraph>
                            {history.message}
                          </Typography.Paragraph>
                          <Divider />
                          {sessionMessageHistory.length > 1 && (
                            <Typography.Title level={5}>
                              Revision History
                            </Typography.Title>
                          )}
                        </Flex>
                      )}
                      {index > 0 && (
                        <ResponseContainer>
                          <Typography.Paragraph>
                            {history.message}
                          </Typography.Paragraph>
                        </ResponseContainer>
                      )}
                    </>
                  ))}
                </Flex>
              )}
              {sessionMessageHistory.length > 0 && (
                <SpinnerComponent spinning={requestLoading}>
                  <Flex vertical gap={16} style={{ marginTop: 16 }}>
                    <Typography.Text>
                      Not quite what you're looking for? Chat to ask for
                      changes, or use the edit button for small changes.
                    </Typography.Text>
                    <Input.TextArea
                      rows={3}
                      onChange={(event) => setRequestText(event.target.value)}
                      onKeyPress={handleKeyPress}
                      placeholder="What would you like to change?"
                    />
                  </Flex>
                </SpinnerComponent>
              )}
            </Card>
          </InputContainer>

          <ResponseContainerWrapper>
            {(responseTextToShow || responseUrl !== "") && (
              <ActionContainer>
                {type !== ChatSessionType.CHAT && (
                  <ButtonsContainer>
                    {type !== ChatSessionType.COLORING_SHEET && (
                      <Button
                        icon={<FontAwesomeIcon icon={faPencil} />}
                        disabled={requestLoading || saveLoading}
                        onClick={() => setEditing(!editing)}
                      >
                        {editing ? "Done Manually Editing" : "Manually Edit"}
                      </Button>
                    )}
                    {type !== ChatSessionType.COLORING_SHEET && (
                      <Button
                        icon={<FontAwesomeIcon icon={faFilePen} />}
                        disabled={
                          requestLoading || saveLoading || responseText === ""
                        }
                        type="primary"
                        onClick={() =>
                          publishedContent
                            ? saveContent(true)
                            : setSaveModalOpen(true)
                        }
                      >
                        {publishedContent ? "Update" : "Save"} {buttonTitle}
                      </Button>
                    )}
                    {type === ChatSessionType.COLORING_SHEET && (
                      <Button
                        icon={<FontAwesomeIcon icon={faFilePen} />}
                        disabled={
                          requestLoading || saveLoading || responseUrl === ""
                        }
                        type="primary"
                        onClick={() => setSaveModalOpen(true)}
                      >
                        Save {buttonTitle}
                      </Button>
                    )}
                    {publishedContent &&
                      type !== ChatSessionType.COLORING_SHEET && (
                        <Button
                          icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                          disabled={requestLoading || saveLoading}
                          onClick={() => setSaveModalOpen(true)}
                        >
                          Save as New {buttonTitle}
                        </Button>
                      )}
                    {type === ChatSessionType.COLORING_SHEET && (
                      <Button
                        icon={<FontAwesomeIcon icon={faPrint} />}
                        onClick={handlePrint}
                        disabled={responseUrl === ""}
                      >
                        Print
                      </Button>
                    )}
                  </ButtonsContainer>
                )}
                {type !== ChatSessionType.COLORING_SHEET && (
                  <ResponseContainer>
                    {editing ? (
                      <Input.TextArea
                        rows={6}
                        value={responseTextToShow}
                        onChange={(e) => setResponseText(e.target.value)}
                        onBlur={() => setEditing(false)}
                        autoSize={{ minRows: 6 }}
                      />
                    ) : (
                      <Typography.Paragraph>
                        {responseTextToShow}
                      </Typography.Paragraph>
                    )}
                  </ResponseContainer>
                )}
                {type === ChatSessionType.COLORING_SHEET && (
                  <Image
                    style={{ width: "auto", height: "70vh" }}
                    preview={false}
                    src={responseUrl}
                  />
                )}
              </ActionContainer>
            )}
          </ResponseContainerWrapper>
        </ChatContainer>
      </Flex>
      <Modal
        open={saveModalOpen}
        onOk={() =>
          type === ChatSessionType.COLORING_SHEET
            ? saveColoringSheet()
            : saveContent()
        }
        onCancel={() => setSaveModalOpen(false)}
        title={`What should we call this ${buttonTitle.toLowerCase()}?`}
      >
        <Flex vertical>
          <Input
            placeholder={titlePlaceholderText}
            value={contentTitle}
            onChange={(event) => setContentTitle(event.currentTarget.value)}
          />
        </Flex>
      </Modal>
    </Container>
  );
};

export default TextInterface;
