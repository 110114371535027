import {
  Button,
  Flex,
  Form,
  Input,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useAuth } from "../../../context/auth-context";
import { useNavigate } from "react-router-dom";
import { OnboardingStep } from "../../../types/user";
import SpinnerComponent from "../../common/spinner";

type FormFieldType = {
  users: {
    email: string;
    studentFirstName: string;
    studentLastName: string;
  }[];
};

const SchoolAdminOnboardingStep2Component = () => {
  const { fetchWithToken } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleNextStep = async () => {
    setLoading(true);
    try {
      await fetchWithToken({
        url: `/onboarding`,
        method: "POST",
        body: {
          updatedStep: `STEP_${OnboardingStep.STEP_3}`,
          complete: true,
        },
      });

      navigate("/");
    } catch (error) {
      notification.error({ message: "Error moving to next step" });
    }

    setLoading(false);
  };

  const handleCompleteStep = async (values: FormFieldType) => {
    setLoading(true);

    try {
      const response = await fetchWithToken({
        url: "/parent/create-many",
        method: "POST",
        body: values.users,
      });

      if (response) {
        setSuccess(true);
        handleNextStep();
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <Flex vertical style={{ textAlign: "center", marginTop: 40 }}>
      <Typography.Title level={2}>Complete your school setup</Typography.Title>
      <Typography.Text>
        Add each student to your school and invite their parents to access and
        manage their profile. If you don't have their parent's information
        ready, you can invite them to their child's profile later on.
      </Typography.Text>
      <Flex justify="center">
        <SpinnerComponent spinning={loading}>
          <Form
            onFinish={handleCompleteStep}
            style={{ maxWidth: 600, marginTop: 20 }}
            autoComplete="off"
            initialValues={{ users: [{}, {}, {}, {}, {}] }}
          >
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item {...restField} name={[name, "email"]}>
                        <Input placeholder="Parent Email" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "studentFirstName"]}
                      >
                        <Input placeholder="Student First Name" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "studentLastName"]}
                      >
                        <Input placeholder="Student Last Name" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Invite Additional Students
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Flex justify="space-between">
              <Button type="primary" htmlType="submit">
                Send Invites
              </Button>
              <Button type="default" onClick={() => handleNextStep()}>
                Skip
              </Button>
            </Flex>
          </Form>
        </SpinnerComponent>
      </Flex>
    </Flex>
  );
};

export default SchoolAdminOnboardingStep2Component;
