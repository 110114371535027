/** @jsxImportSource @emotion/react */
import { Flex, Typography } from "antd";
import styled from "styled-components";
import { ChatSessionType } from "../../types/chat-session";
import CreateTextInterface from "./text-interface/create";
import { useLocation, useParams } from "react-router-dom";
import EditTextInterface, {
  CreateEditTextInterfaceProps,
} from "./text-interface/edit";
import {
  BUTTON_TITLE_BY_TYPE,
  CREATE_PAGE_PLACEHOLDER_TEXT_BY_TYPE,
  CREATE_PAGE_SUBTITLE_BY_TYPE,
  CREATE_PAGE_TITLE_BY_TYPE,
} from "./util";

const Container = styled.div`
  margin: 24px 24px;
  width: 100%;
`;

const CreateEditPublishedContent = () => {
  const isNewActivity = useLocation().pathname.includes("new");
  const { lowerCaseChatSessionType } = useParams<{
    lowerCaseChatSessionType: string;
  }>();

  const chatSessionType = lowerCaseChatSessionType
    ?.toUpperCase()
    .replace("-", "_") as ChatSessionType;

  if (!chatSessionType) {
    return (
      <Typography.Title>Not found. Please check your URL.</Typography.Title>
    );
  }

  const textInterfaceProps: CreateEditTextInterfaceProps = {
    title: CREATE_PAGE_TITLE_BY_TYPE[chatSessionType],
    subtitle: CREATE_PAGE_SUBTITLE_BY_TYPE[chatSessionType],
    type: chatSessionType,
    buttonTitle: BUTTON_TITLE_BY_TYPE[chatSessionType],
    titlePlaceholderText: CREATE_PAGE_PLACEHOLDER_TEXT_BY_TYPE[chatSessionType],
  };

  if (isNewActivity) {
    return (
      <Container>
        <Flex vertical gap={16} align="start">
          <CreateTextInterface {...textInterfaceProps} />
        </Flex>
      </Container>
    );
  }

  return (
    <Container>
      <Flex vertical gap={16} align="start">
        <EditTextInterface {...textInterfaceProps} />
      </Flex>
    </Container>
  );
};

export default CreateEditPublishedContent;
