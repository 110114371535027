/** @jsxImportSource @emotion/react */
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Typography, Card, Flex, Button, Image } from "antd";
import { BUTTON_TITLE_BY_TYPE } from "./util";
import {
  activityPublishedContentState,
  coloringSheetPublishedContentState,
  songPublishedContentState,
  storyPublishedContentState,
} from "../../states/published-content";
import { PublishedContent } from "../../types/published-content";
import { ChatSessionType } from "../../types/chat-session";
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const { Title, Paragraph } = Typography;

const ViewPublishedContent: React.FC = () => {
  const { lowerCaseChatSessionType, publishedContentId } = useParams<{
    lowerCaseChatSessionType: string;
    publishedContentId: string;
  }>();

  const chatSessionType = lowerCaseChatSessionType
    ?.toUpperCase()
    .replace("-", "_") as ChatSessionType;
  const navigate = useNavigate();
  const activityState = useRecoilValue(activityPublishedContentState);
  const storyState = useRecoilValue(storyPublishedContentState);
  const songState = useRecoilValue(songPublishedContentState);
  const coloringSheetState = useRecoilValue(coloringSheetPublishedContentState);

  const buttonTitle = BUTTON_TITLE_BY_TYPE[chatSessionType as ChatSessionType];

  const handlePrint = (printUrl: string) => {
    const printWindow = window.open(printUrl, "_blank");
    if (printWindow) {
      printWindow.onload = () => {
        printWindow.print();
      };
    }
  };

  let stateToUse: PublishedContent[] = [];

  // TODO: Refactor this to a shared component
  switch (chatSessionType) {
    case ChatSessionType.ACTIVITY:
      stateToUse = activityState;
      break;
    case ChatSessionType.STORY:
      stateToUse = storyState;
      break;
    case ChatSessionType.SONG:
      stateToUse = songState;
      break;
    case ChatSessionType.COLORING_SHEET:
      stateToUse = coloringSheetState;
      break;
  }
  const selectedPublishedContent = stateToUse.find(
    (publishedContnent) => publishedContnent.id === Number(publishedContentId)
  );

  if (!selectedPublishedContent) {
    return <Typography.Title>{buttonTitle} not found</Typography.Title>;
  }

  const paragraphs = selectedPublishedContent.text
    ? selectedPublishedContent.text
        .split("\n")
        .map((paragraph, index) => (
          <Paragraph key={index}>{paragraph}</Paragraph>
        ))
    : undefined;

  return (
    <Flex vertical>
      <div
        css={css`
          margin-left: auto;
        `}
      >
        {selectedPublishedContent.type !== ChatSessionType.COLORING_SHEET && (
          <Button
            onClick={() =>
              navigate(
                `/tools/${chatSessionType.toLowerCase()}/${publishedContentId}/edit`
              )
            }
          >
            Edit
          </Button>
        )}
        {selectedPublishedContent.type === ChatSessionType.COLORING_SHEET && (
          <Button
            icon={<FontAwesomeIcon icon={faPrint} />}
            onClick={() => handlePrint(selectedPublishedContent.attachmentUrl!)}
            disabled={selectedPublishedContent.attachmentUrl === null}
          >
            Print
          </Button>
        )}
      </div>
      {selectedPublishedContent.type === ChatSessionType.COLORING_SHEET && (
        <Flex vertical>
          <Title level={3}>{selectedPublishedContent.title}</Title>
          <Image
            src={selectedPublishedContent.attachmentUrl}
            style={{ maxHeight: "60vh", width: "auto" }}
            preview={false}
          />
        </Flex>
      )}
      {selectedPublishedContent.type !== ChatSessionType.COLORING_SHEET && (
        <Card style={{ margin: "20px 0" }}>
          <Title level={3}>{selectedPublishedContent.title}</Title>
          {paragraphs}
        </Card>
      )}
    </Flex>
  );
};

export default ViewPublishedContent;
