/** @jsxImportSource @emotion/react */
import { Alert, Button, Flex, Form, Input } from "antd";
import { useState } from "react";
import { Card, Container } from "react-bootstrap";
import styled from "styled-components";
import { API_URL } from "../../util";
import { useNavigate, useParams } from "react-router-dom";
import { SuccessResponse } from "../../types/common";

const StyledFlexContainer = styled(Flex)`
  height: 100vh;
`;

type FormFieldType = {
  password?: string;
  confirmPassword?: string;
};

const ConfirmPasswordResetComponent = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<"SUCCESS" | "ERROR" | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const { passwordResetToken } = useParams();

  const handleResetPassword = async (values: FormFieldType) => {
    const { password } = values;

    try {
      setLoading(true);
      setSuccess(undefined);
      const resetPasswordResponse = await fetch(`${API_URL}/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: passwordResetToken, password }),
      });

      const response = (await resetPasswordResponse.json()) as SuccessResponse;

      setSuccess(response.success ? "SUCCESS" : "ERROR");
    } catch (error) {
      setSuccess("ERROR");
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <Container>
      <StyledFlexContainer justify="center" align="center" vertical>
        {success === "SUCCESS" && (
          <Flex vertical>
            <Alert
              style={{ margin: 20 }}
              message="Password reset successfully. You can now login."
              type="success"
            />
            <Button onClick={() => navigate("/sign-in")}>
              Sign In With New Password
            </Button>
          </Flex>
        )}
        {success === "ERROR" && (
          <Alert
            message="Error completing password reset. Please verify your password reset URL is correct."
            type="error"
          />
        )}
        {success === undefined && (
          <Card style={{ padding: 20 }}>
            <Card.Title>Reset Password</Card.Title>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              labelAlign="left"
              style={{ maxWidth: 600, minWidth: 400 }}
              onFinish={(values: FormFieldType) => handleResetPassword(values)}
              onFinishFailed={(error) => console.log(error)}
              autoComplete="off"
            >
              <Form.Item<FormFieldType>
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password should be at least 8 characters long",
                    min: 8,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item<FormFieldType>
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Change Password
              </Button>
            </Form>
          </Card>
        )}
      </StyledFlexContainer>
    </Container>
  );
};

export default ConfirmPasswordResetComponent;
