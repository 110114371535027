/** @jsxImportSource @emotion/react */
import { Card, Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  activityPublishedContentState,
  coloringSheetPublishedContentState,
  songPublishedContentState,
  storyPublishedContentState,
} from "../../states/published-content";

const Container = styled.div`
  margin: 24px 32px 0px;
`;

const StyledCard = styled(Card)`
  width: 300px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const AiToolsHome = () => {
  const navigate = useNavigate();
  const existingActivities = useRecoilValue(activityPublishedContentState);
  const existingStories = useRecoilValue(storyPublishedContentState);
  const existingSongs = useRecoilValue(songPublishedContentState);
  const existingColoringSheets = useRecoilValue(
    coloringSheetPublishedContentState
  );

  const handleCreateActivityClick = () => {
    if (existingActivities.length > 0) {
      navigate("/tools/activity");
    } else {
      navigate("/tools/activity/new");
    }
  };

  const handleCreateStoryClick = () => {
    if (existingStories.length > 0) {
      navigate("/tools/story");
    } else {
      navigate("/tools/story/new");
    }
  };

  const handleCreateSongClick = () => {
    if (existingSongs.length > 0) {
      navigate("/tools/song");
    } else {
      navigate("/tools/song/new");
    }
  };

  const handleCreateColoringSheetClick = () => {
    if (existingColoringSheets.length > 0) {
      navigate("/tools/coloring-sheet");
    } else {
      navigate("/tools/coloring-sheet/new");
    }
  };

  return (
    <Container>
      <Typography.Title level={3}>Smart Tools</Typography.Title>
      <Flex gap="large" wrap="wrap">
        <StyledCard title="Sunny" onClick={() => navigate("/tools/chat")}>
          <Typography.Text>
            Your friendly AI assistant for pre-K teachers.
          </Typography.Text>
        </StyledCard>
        <StyledCard
          title="Coloring Sheets"
          onClick={handleCreateColoringSheetClick}
        >
          <Typography.Text>
            Create a custom coloring sheet for your students
          </Typography.Text>
        </StyledCard>
        <StyledCard title="Activities" onClick={handleCreateActivityClick}>
          <Typography.Text>
            Create an activity for your students
          </Typography.Text>
        </StyledCard>
        <StyledCard title="Story Time" onClick={handleCreateStoryClick}>
          <Typography.Text>
            Create a custom story for your students
          </Typography.Text>
        </StyledCard>
        <StyledCard title="Music" onClick={handleCreateSongClick}>
          <Typography.Text>
            Write a song for your students to sing in any tune
          </Typography.Text>
        </StyledCard>
      </Flex>
    </Container>
  );
};

export default AiToolsHome;
