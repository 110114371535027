/** @jsxImportSource @emotion/react */
import { Button, Card, Flex, Layout, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import { css } from "@emotion/react";
import { Col, Container, Row } from "react-bootstrap";
import {
  DefaultValue,
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilValue,
} from "recoil";
import { classroomsState } from "../../states/classrooms";
import AvatarListComponent from "../classroom/avatar-list";
import { Student, StudentStatusType } from "../../types/student";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import StudentTable from "../classroom/student-table";
import ClassroomListComponent from "./classroom-list";

const SchoolAdminHomeComponent = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const classrooms = useRecoilValue(classroomsState);
  const [presentStudents, setPresentStudents] = useState<
    Map<number, Student[]>
  >(new Map());
  const [allStudents, setAllStudents] = useState<Student[]>([]);
  const refreshClassrooms = useRecoilRefresher_UNSTABLE(classroomsState);

  useEffect(() => {
    const updatedPresentStudents = new Map<number, Student[]>();
    let allStudents: Student[] = [];

    for (const classroom of classrooms) {
      allStudents = [...allStudents, ...classroom.students];

      const presentStudents = classroom.students.filter(
        (student) => student.status === StudentStatusType.CHECKED_IN
      );

      updatedPresentStudents.set(classroom.id, presentStudents);
    }

    setPresentStudents(updatedPresentStudents);
    setAllStudents(allStudents);
  }, [classrooms]);

  useEffect(() => {
    refreshClassrooms();
  }, [refreshClassrooms]);

  return (
    <Layout style={{ backgroundColor: "transparent" }}>
      <Content
        style={{
          margin: "24px 16px 0",
          overflow: "initial",
        }}
      >
        <Container fluid>
          <Row
            css={css`
              gap: 8px;
            `}
          >
            <Flex justify="space-between" align="center">
              <Typography.Title level={4}>All Classrooms</Typography.Title>
              <Tooltip trigger="hover" title="Create new classroom">
                <Button icon={<FontAwesomeIcon icon={faPlus} />} />
              </Tooltip>
            </Flex>
            <ClassroomListComponent
              classrooms={classrooms}
              presentStudents={presentStudents}
            />
            <StudentTable
              title={
                <Typography.Title level={4}>All Students</Typography.Title>
              }
              loading={false}
              students={allStudents}
              setStudents={setAllStudents}
            />
          </Row>
        </Container>
      </Content>
    </Layout>
  );
};

export default SchoolAdminHomeComponent;
