/** @jsxImportSource @emotion/react */
import { Alert, Button, Divider, Flex, Form, Input, Typography } from "antd";
import { useState } from "react";
import { Card, Container } from "react-bootstrap";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { accessTokenState } from "../../states/auth";
import { UserAuthResponse } from "../../types/user";
import { API_URL, setAuthCookie } from "../../util";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "../common/spinner";

const StyledFlexConstainer = styled(Flex)`
  height: 100vh;
`;

type FormFieldType = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
};

const SignUpComponent = () => {
  const [, setAccessToken] = useRecoilState(accessTokenState);
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState(false);
  const navigate = useNavigate();

  const handleCreateAccount = async (values: FormFieldType) => {
    const { email, password, firstName, lastName } = values;

    try {
      setLoading(true);
      const createAccountResponse = await fetch(`${API_URL}/sign-up`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, firstName, lastName }),
      });

      const response = (await createAccountResponse.json()) as UserAuthResponse;
      if (response.accessToken) {
        setAccessToken(response.accessToken);
        setAuthCookie(response.accessToken);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    setAuthError(true);
  };

  return (
    <Container>
      <StyledFlexConstainer justify="center" align="center" vertical>
        {authError && (
          <Alert
            type="error"
            message="There was an error creating your account. Please try again"
            style={{ margin: "16px 0" }}
          />
        )}
        <SpinnerComponent spinning={loading}>
          <Card style={{ padding: 20 }}>
            <Card.Title>Create a Clear Day account</Card.Title>
            <Form
              name="basic"
              labelAlign="left"
              labelCol={{ span: 9 }}
              onFinish={(values: FormFieldType) => handleCreateAccount(values)}
              onFinishFailed={(error) => console.log(error)}
              autoComplete="off"
            >
              <Form.Item<FormFieldType>
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: "First Name is required" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item<FormFieldType>
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: "Last Name is required" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FormFieldType>
                label="Email"
                name="email"
                rules={[{ required: true, message: "Email is required" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FormFieldType>
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password should be at least 8 characters long",
                    min: 8,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item<FormFieldType>
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator: (_, value) => {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Button type="primary" htmlType="submit" block>
                Create Account
              </Button>
            </Form>
          </Card>
        </SpinnerComponent>
        <Divider />
        <Typography.Title level={3}>Already registered?</Typography.Title>
        <Button onClick={() => navigate("/sign-in")}>Login</Button>
      </StyledFlexConstainer>
    </Container>
  );
};

export default SignUpComponent;
