export enum EventType {
  NOTE = "NOTE",
  BATHROOM = "BATHROOM",
  NAP = "NAP",
  FOOD = "FOOD",
  ACTIVITY = "ACTIVITY",
  CHECKED_IN = "CHECKED_IN",
  CHECKED_OUT = "CHECKED_OUT",
  MARKED_ABSENT = "MARKED_ABSENT",
}

export enum FoodEventType {
  BREAKFAST = "BREAKFAST",
  AM_SNACK = "AM_SNACK",
  LUNCH = "LUNCH",
  PM_SNACK = "PM_SNACK",
  DINNER = "DINNER",
}

export enum MessageType {
  TEACHER,
  PARENT,
  SYSTEM,
  SCHOOL_ADMIN,
}
interface Message {
  id: number;
  teacherId: number;
  studentId: number;
  text: string;
  type: MessageType;
}
export interface Event {
  id: number;
  type: EventType;
  teacherId: number;
  studentId: number;
  messages: Message[];
  amountCompleted: number | null;
  foodEventType: FoodEventType | null;
  createdAt: Date;
}

export interface EventSummary {
  text: string;
  createdAt: Date;
}

export interface EventCreateOneRequest
  extends Omit<
    Event,
    | "id"
    | "createdAt"
    | "studentId"
    | "teacherId"
    | "messages"
    | "foodEventType"
  > {
  studentIds: number[];
  message?: string;
  foodEventType?: FoodEventType;
}

export interface EventCreateManyRequest {
  events: EventCreateOneRequest[];
}
