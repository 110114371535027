/** @jsxImportSource @emotion/react */
import { Alert, Button, Divider, Flex, Form, Input } from "antd";
import { useState } from "react";
import { Card, Container } from "react-bootstrap";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { accessTokenState } from "../../states/auth";
import { UserAuthResponse } from "../../types/user";
import { API_URL, setAuthCookie } from "../../util";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "../common/spinner";

const StyledFlexContainer = styled(Flex)`
  height: 100vh;
`;

type FormFieldType = {
  email?: string;
  password?: string;
};

const SignInComponent = () => {
  const [, setAccessToken] = useRecoilState(accessTokenState);
  const [loading, setLoading] = useState(false);
  const [authError, setAuthError] = useState(false);
  const navigate = useNavigate();

  const handleCreateAccount = async (values: FormFieldType) => {
    const { email, password } = values;

    try {
      setAuthError(false);
      setLoading(true);
      const createAccountResponse = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const response = (await createAccountResponse.json()) as UserAuthResponse;

      if (response.accessToken) {
        setAccessToken(response.accessToken);
        setAuthCookie(response.accessToken);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
    setAuthError(true);
  };

  return (
    <Container fluid>
      <StyledFlexContainer justify="center" align="center" vertical>
        {authError && (
          <Alert
            type="error"
            message="Email or password incorrect. Please try again"
            style={{ margin: "16px 0" }}
          />
        )}
        <SpinnerComponent spinning={loading}>
          <Card style={{ padding: 20 }}>
            <Card.Title>Login to your Clear Day Account</Card.Title>
            <Form
              name="basic"
              labelAlign="left"
              labelCol={{ span: 8 }}
              onFinish={(values: FormFieldType) => handleCreateAccount(values)}
              onFinishFailed={(error) => console.log(error)}
              autoComplete="off"
            >
              <Form.Item<FormFieldType>
                label="Email"
                name="email"
                rules={[{ required: true, message: "Email is required" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FormFieldType>
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password />
              </Form.Item>

              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
              <Flex justify="center" style={{ marginTop: 20 }}>
                <Button
                  type="link"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password
                </Button>
              </Flex>
            </Form>
          </Card>
        </SpinnerComponent>
        {/*<Divider />
        <Button onClick={() => navigate("/sign-up")}>Create an Account</Button>*/}
      </StyledFlexContainer>
    </Container>
  );
};

export default SignInComponent;
