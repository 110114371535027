export enum ChatSessionType {
  STUDENT_EVENT = "STUDENT_EVENT",
  ACTIVITY = "ACTIVITY",
  CHAT = "CHAT",
  STORY = "STORY",
  SONG = "SONG",
  COLORING_SHEET = "COLORING_SHEET",
}

export enum ChatSessionMessageType {
  USER = "USER",
  ASSISTANT = "ASSISTANT",
}

export interface ChatSessionCreateRequest {
  type: ChatSessionType;
}

export interface ChatSessionCreateResponse extends ChatSessionCreateRequest {
  id: number;
}

export interface ChatSessionMessage {
  message: string;
  type: ChatSessionMessageType;
}

export interface ChatSessionGetHistoryResponse
  extends ChatSessionCreateResponse {
  history: ChatSessionMessage[];
}
