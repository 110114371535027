import styled from "styled-components";

export const TableContainer = styled.div`
  border: 2px solid #ccccff;
  border-radius: 8px;
  padding: 2px;

  .ant-table {
    //background-color: transparent !important;
  }
`;
