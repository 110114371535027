import { useRecoilValue } from "recoil";
import { useEffect } from "react";
import { Steps } from "antd";
import { onboardingState } from "../../states/auth";
import { OnboardingStep } from "../../types/user";
import SpinnerComponent from "../common/spinner";
import { useNavigate, useParams } from "react-router-dom";
import OnboardingStep1Component from "./step_1";
import OnboardingStep2Component from "./step_2";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonChalkboard } from "@fortawesome/free-solid-svg-icons";
import OnboardingStep3Component from "./step_3";

const OnboardingComponent = () => {
  const { step } = useParams<{ step: string }>();
  const onboarding = useRecoilValue(onboardingState);
  const navigate = useNavigate();

  useEffect(() => {
    if (onboarding?.complete) {
      navigate("/");
    }
  }, [onboarding, navigate, step]);

  const intStep = parseInt(step ?? "1") - 1;

  let stepToRender: JSX.Element = <SpinnerComponent size="large" />;

  if (step === OnboardingStep.STEP_1.toString()) {
    stepToRender = <OnboardingStep1Component />;
  }

  if (step === OnboardingStep.STEP_2.toString()) {
    stepToRender = <OnboardingStep2Component />;
  }

  if (step === OnboardingStep.STEP_3.toString()) {
    stepToRender = <OnboardingStep3Component />;
  }

  // TODO: Dynamic steps for each onboarding role
  return (
    <Container>
      <Steps
        current={intStep}
        style={{ marginBottom: "24px" }}
        items={[
          {
            title: "Invite Teachers",
            icon:
              intStep === 0 ? (
                <FontAwesomeIcon icon={faPersonChalkboard} />
              ) : undefined,
          },
          {
            title: "Create Classrooms",
          },
          {
            title: "Invite Parents",
          },
        ]}
      />

      {stepToRender}
    </Container>
  );
};

export default OnboardingComponent;
