/** @jsxImportSource @emotion/react */
import { Alert, Button, Divider, Flex, Form, Input } from "antd";
import { useState } from "react";
import { Card, Container } from "react-bootstrap";
import styled from "styled-components";
import { API_URL } from "../../util";
import { useNavigate } from "react-router-dom";
import { SuccessResponse } from "../../types/common";
import SpinnerComponent from "../common/spinner";

const StyledFlexContainer = styled(Flex)`
  height: 100vh;
`;

type FormFieldType = {
  email?: string;
};

const ForgotPasswordComponent = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<"SUCCESS" | "ERROR" | undefined>(
    undefined
  );
  const navigate = useNavigate();

  const handleRequestPasswordReset = async (values: FormFieldType) => {
    const { email } = values;

    try {
      setLoading(true);
      setSuccess(undefined);
      const requestPasswordResetResponse = await fetch(
        `${API_URL}/request-password-reset`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const response =
        (await requestPasswordResetResponse.json()) as SuccessResponse;

      setSuccess(response.success ? "SUCCESS" : "ERROR");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container fluid>
      <StyledFlexContainer justify="center" align="center" vertical>
        {success === "SUCCESS" && (
          <Alert
            style={{ margin: 20 }}
            message="Password reset requested successfully. If an account with that email
          exists you will receive a password reset email."
            type="success"
          />
        )}
        {success === "ERROR" && (
          <Alert
            style={{ margin: 20 }}
            message="Error requesting a password reset. Please try again."
            type="error"
          />
        )}
        <SpinnerComponent spinning={loading}>
          <Card style={{ padding: 20 }}>
            <Card.Title>Forgot your password?</Card.Title>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              labelAlign="left"
              onFinish={(values: FormFieldType) =>
                handleRequestPasswordReset(values)
              }
              onFinishFailed={(error) => console.log(error)}
              autoComplete="off"
            >
              <Form.Item<FormFieldType>
                label="Email"
                name="email"
                rules={[{ required: true, message: "Email is required" }]}
              >
                <Input />
              </Form.Item>

              <Button type="primary" htmlType="submit" block>
                Reset Password
              </Button>
            </Form>
          </Card>
        </SpinnerComponent>
        <Divider />
        <Button onClick={() => navigate("/sign-in")}>Log In Instead</Button>
      </StyledFlexContainer>
    </Container>
  );
};

export default ForgotPasswordComponent;
