import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Student, StudentStatusType } from "../../types/student";
import { Button, Flex, Popover, Table, Tooltip, Typography } from "antd";
import UserAvatarComponent from "./user-avatar";
import AttendanceTag from "./attendance-tag";
import styled from "styled-components";
import LoggerModal, { EVENT_BUTTON_TEXT } from "../student/logger-modal";
import { EventType } from "../../types/event";
import { TIMELINE_ICONS } from "../student";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { TableContainer } from "../common/styled";

export const HEADER_TEXT = {
  [StudentStatusType.CHECKED_IN]: "Present Students",
  [StudentStatusType.CHECKED_OUT]: "Checked Out Students",
  [StudentStatusType.NOT_PRESENT]: "Not Present Students",
  [StudentStatusType.ABSENT]: "Absent Students",
};

const Container = styled.div`
  margin-bottom: 20px;

  .ant-table {
    h5 {
      margin-left: 6px;
    }
  }
`;

const StudentTable = ({
  title,
  students,
  setStudents,
  classroomId,
  loading,
  handleShowAiScreen,
}: {
  title: React.ReactNode;
  students: Student[];
  setStudents?: (updatedStudents: Student[]) => void;
  classroomId?: number;
  loading?: boolean;
  handleShowAiScreen?: (student: Student) => void;
}) => {
  const navigate = useNavigate();
  const [showStudentEventModal, setShowStudentEventModal] = useState(false);
  const [selectedEventToLog, setSelectedEventToLog] = useState<
    EventType | undefined
  >();
  const [selectedStudentsToLog, setSelectedStudentsTolog] = useState<Student[]>(
    []
  );
  const [showAiEventScreen, setShowAiEventScreen] = useState(false);
  const [activePopover, setActivePopover] = useState<number | undefined>();

  const handleChangeStudentStatus = async (updatedStudent: Student) => {
    try {
      const updatedStudents = [...students];
      const indexToUpdate = updatedStudents.findIndex(
        (student) => student.id === updatedStudent.id
      );
      if (indexToUpdate !== -1) {
        updatedStudents[indexToUpdate] = updatedStudent;
      }

      if (setStudents) {
        setStudents(updatedStudents);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickLogEvent = (student: Student, eventType?: EventType) => {
    setActivePopover(undefined);
    setSelectedEventToLog(eventType);
    setSelectedStudentsTolog([student]);
    setShowStudentEventModal(true);
  };

  const handleClickAiEventButton = (student: Student) => {
    if (handleShowAiScreen) {
      handleShowAiScreen(student);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "60%",
      render: (text: string, record: Student) => (
        <Flex
          style={{ cursor: "pointer" }}
          gap={10}
          align="center"
          onClick={() =>
            navigate(
              classroomId !== undefined
                ? `/classroom/${classroomId}/student/${record.id}`
                : `/student/${record.id}`
            )
          }
        >
          <UserAvatarComponent
            user={{
              firstName: record.firstName,
              lastName: record.lastName,
            }}
          />
          {record.firstName} {record.lastName}
        </Flex>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 140,
      sorter: (a: Student, b: Student) => {
        const statusOrder = [
          "CHECKED_IN",
          "NOT_PRESENT",
          "CHECKED_OUT",
          "ABSENT",
        ];
        return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
      },
      render: (status: StudentStatusType, record: Student) => (
        <AttendanceTag
          student={record}
          handleOnStatusChange={handleChangeStudentStatus}
        />
      ),
    },
    {
      title: "",
      key: "action",
      hidden: !Boolean(handleShowAiScreen),
      render: (text: string, record: Student) => (
        <Tooltip title="Record a highlight for this child">
          <Popover
            title="Select an event type"
            trigger="click"
            open={activePopover === record.id}
            onOpenChange={() => setActivePopover(undefined)}
            content={
              <Flex vertical gap={10}>
                {[
                  EventType.BATHROOM,
                  EventType.FOOD,
                  EventType.ACTIVITY,
                  EventType.NAP,
                ].map((eventType) => (
                  <Button
                    shape="round"
                    size="large"
                    icon={TIMELINE_ICONS[eventType]}
                    onClick={() => handleClickLogEvent(record, eventType)}
                  >
                    {EVENT_BUTTON_TEXT[eventType]}
                  </Button>
                ))}
                <Button
                  shape="round"
                  size="large"
                  onClick={() => handleClickAiEventButton(record)}
                  icon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
                >
                  Magic Assist
                </Button>
              </Flex>
            }
          >
            <Button
              icon={<FontAwesomeIcon icon={faPenToSquare} />}
              onClick={() =>
                setActivePopover(
                  activePopover === undefined ? record.id : undefined
                )
              }
            />
          </Popover>
        </Tooltip>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Student[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };

  return (
    <Container>
      {title}
      <TableContainer>
        <Table
          rowKey="id"
          bordered={false}
          showHeader={false}
          columns={columns}
          dataSource={students}
          pagination={false}
          loading={loading}
        />
      </TableContainer>
      {showStudentEventModal && selectedEventToLog && (
        <LoggerModal
          eventType={selectedEventToLog}
          open={showStudentEventModal}
          onOk={() => setShowStudentEventModal(false)}
          onCancel={() => setShowStudentEventModal(false)}
          studentsToLog={selectedStudentsToLog}
          allStudentsInClass={students}
        />
      )}
    </Container>
  );
};

export default StudentTable;
