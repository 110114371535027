/** @jsxImportSource @emotion/react */
import { Avatar } from "antd";
import UserAvatarComponent from "./user-avatar";

const AvatarListComponent = ({
  users,
}: {
  users: {
    firstName?: string;
    lastName?: string;
    id: number;
  }[];
}) => {
  return (
    <Avatar.Group maxCount={8}>
      {users.map((user) => (
        <UserAvatarComponent user={user} key={user.id} />
      ))}
    </Avatar.Group>
  );
};

export default AvatarListComponent;
