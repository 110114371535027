/** @jsxImportSource @emotion/react */
import { Card, Flex, Layout, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import { css } from "@emotion/react";
import { Col, Container, Row } from "react-bootstrap";
import { TeacherFindOneResponse } from "../../types/teacher";
import { useAuth } from "../../context/auth-context";
import { useRecoilValue } from "recoil";
import { classroomsState } from "../../states/classrooms";
import {
  currentUserNameWithGreetingState,
  currentUserState,
} from "../../states/auth";
import ClassroomListComponent from "./classroom-list";
import { Student, StudentStatusType } from "../../types/student";
import { parentsStudentsState } from "../../states/student";
import StudentTable from "../classroom/student-table";
import UserAvatarComponent from "../classroom/user-avatar";
import { StudentsWithClassroomsResponse } from "../../types/parent";

const ParentHomeComponent = () => {
  const navigate = useNavigate();

  const students = useRecoilValue(parentsStudentsState);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "60%",
      render: (
        text: string,
        record: StudentsWithClassroomsResponse["students"][0]
      ) => (
        <Flex
          style={{ cursor: "pointer" }}
          gap={10}
          align="center"
          onClick={() => navigate(`/student/${record.id}`)}
        >
          <UserAvatarComponent
            user={{
              firstName: record.firstName,
              lastName: record.lastName,
            }}
          />
          {record.firstName} {record.lastName}
        </Flex>
      ),
    },
    {
      title: "Classroom",
      dataIndex: "classroomName",
      key: "classroomName",
      render: (
        text: string,
        record: StudentsWithClassroomsResponse["students"][0]
      ) => record.classroom?.name,
    },
  ];

  return (
    <Layout style={{ margin: 16, backgroundColor: "transparent" }}>
      <Content
        style={{
          margin: "24px 16px 0",
          overflow: "initial",
        }}
      >
        <Container fluid>
          <Row
            css={css`
              gap: 8px;
            `}
          >
            <Table
              rowKey="id"
              bordered={false}
              showHeader={true}
              columns={columns}
              dataSource={students}
              pagination={false}
            />
          </Row>
        </Container>
      </Content>
    </Layout>
  );
};

export default ParentHomeComponent;
