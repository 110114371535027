import { Button, Flex, Form, Input, Typography, notification } from "antd";
import { useState } from "react";
import { useAuth } from "../../context/auth-context";
import SpinnerComponent from "../common/spinner";

type FormFieldType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const UserSettingsComponent = () => {
  const { fetchWithToken } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleUpdateSettings = async (values: FormFieldType) => {
    setLoading(true);

    try {
      const response = await fetchWithToken({
        url: "/user/update",
        method: "POST",
        body: values,
      });

      if (response) {
        notification.success({ message: "Settings updated successfully" });
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: "Error updating settings" });
    }

    setLoading(false);
  };

  return (
    <Flex vertical style={{ textAlign: "center", marginTop: 40 }}>
      <Typography.Title level={2}>User Settings</Typography.Title>
      <Typography.Text>
        Update your personal information and password here.
      </Typography.Text>

      <Flex justify="center">
        <SpinnerComponent spinning={loading}>
          <Form
            onFinish={handleUpdateSettings}
            style={{ maxWidth: 400, marginTop: 20 }}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="New Password"
              rules={[
                {
                  min: 8,
                  message: "Password must be at least 8 characters long",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Settings
              </Button>
            </Form.Item>
          </Form>
        </SpinnerComponent>
      </Flex>
    </Flex>
  );
};

export default UserSettingsComponent;
