/** @jsxImportSource @emotion/react */
import {
  Flex,
  Input,
  Mentions,
  Modal,
  Tag,
  Typography,
  notification,
} from "antd";
import {
  EventCreateOneRequest,
  EventType,
  FoodEventType,
} from "../../types/event";
import { Student } from "../../types/student";
import { useAuth } from "../../context/auth-context";
import { useState } from "react";
import LogEventForm from "./log-event-form";
export const EVENT_BUTTON_TEXT: Record<EventType, string> = {
  [EventType.NOTE]: "",
  [EventType.BATHROOM]: "Bathroom",
  [EventType.NAP]: "Nap",
  [EventType.FOOD]: "Food",
  [EventType.ACTIVITY]: "Activity",
  [EventType.CHECKED_IN]: "",
  [EventType.CHECKED_OUT]: "",
  [EventType.MARKED_ABSENT]: "",
};

const LoggerModal: React.FC<{
  eventType: EventType;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  studentsToLog: Student[];
  allStudentsInClass?: Student[];
}> = ({
  eventType,
  open,
  onOk,
  onCancel,
  studentsToLog,
  allStudentsInClass,
}) => {
  const [selectedStudents, setSelectedStudents] = useState([...studentsToLog]);
  const [foodEventType, setFoodEventType] = useState<FoodEventType | undefined>(
    FoodEventType.BREAKFAST
  );
  const [sliderValue, setSliderValue] = useState(0);
  const [noteText, setNoteText] = useState("");
  const { fetchWithToken } = useAuth();

  const createEvent = async () => {
    if (!eventType) {
      return;
    }

    try {
      const requestBody: EventCreateOneRequest = {
        amountCompleted: sliderValue,
        studentIds: selectedStudents.map((student) => student.id),
        message: noteText,
        type: eventType,
        foodEventType: eventType === EventType.FOOD ? foodEventType : undefined,
      };

      await fetchWithToken({
        url: "/student/event",
        method: "POST",
        body: requestBody,
      });

      notification.success({
        message: "Highlight saved successfully",
      });
    } catch (error) {
      notification.error({
        message: "Error saving highlight",
        description: "Please try again",
      });
    }
  };

  const handleOnOk = async () => {
    await createEvent();
    onOk();
  };

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleAddStudent = (key: string) => {
    const studentId = Number(key);

    const student = allStudentsInClass?.find(
      (student) => student.id === studentId
    );

    // TOOD: handle the error state
    if (student) {
      setSelectedStudents([...selectedStudents, student]);
    }
  };

  return (
    <Modal
      open={open}
      onOk={() => handleOnOk()}
      onCancel={() => handleOnCancel()}
      title={`${EVENT_BUTTON_TEXT[eventType]}: ${selectedStudents[0].firstName} ${selectedStudents[0].lastName}`}
      okText="Save Highlight"
    >
      <Flex vertical gap={10}>
        {/*<Mentions
          options={(allStudentsInClass ?? []).map((student) => ({
            key: student.id.toString(),
            value: `${student.firstName} ${student.lastName}`,
            label: `${student.firstName} ${student.lastName}`,
          }))}
          onSelect={(option, prefix) => handleAddStudent(option.key!)}
        />*/}
        {eventType && (
          <LogEventForm
            eventType={eventType}
            studentsToLog={studentsToLog}
            noteText={noteText}
            setNoteText={setNoteText}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            foodEventType={foodEventType}
            setFoodEventType={setFoodEventType}
          />
        )}
      </Flex>
    </Modal>
  );
};

export default LoggerModal;
