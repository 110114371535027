/** @jsxImportSource @emotion/react */
import {
  Alert,
  Avatar,
  Button,
  Card,
  Collapse,
  Empty,
  Flex,
  Layout,
  Timeline,
  TimelineItemProps,
  Typography,
  Slider,
} from "antd";
//import Timeline from "@mui/lab/Timeline";
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import {
  Student,
  StudentGetDetailsResponse,
  StudentGetEventsResponse,
} from "../../types/student";
import {
  Event,
  EventSummary,
  EventType,
  FoodEventType,
} from "../../types/event";
import LoggerModal from "./logger-modal";
import styled from "styled-components";
import { getHumanReadableEnum, getStudentTag } from "../../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faChildReaching,
  faComment,
  faCookieBite,
  faFaceSmile,
  faGhost,
  faPersonRunning,
  faPersonWalkingArrowRight,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";
import card from "antd/es/card";
import SpinnerComponent from "../common/spinner";
import { useAuth } from "../../context/auth-context";
import AttendanceTag from "../classroom/attendance-tag";
import { css } from "@emotion/react";
import UserAvatarComponent from "../classroom/user-avatar";
import { Classroom } from "../../types/classroom";
import { useRecoilValue } from "recoil";
import { classroomsState } from "../../states/classrooms";
import { Parent } from "../../types/parent";
import { AmountCompletedText } from "./log-event-form";

const { Panel } = Collapse;

export const TIMELINE_COLORS: Record<EventType, string> = {
  [EventType.NOTE]: "#dc3545",
  [EventType.BATHROOM]: "#dc3545",
  [EventType.NAP]: "#6c757d",
  [EventType.FOOD]: "#6c757d",
  [EventType.ACTIVITY]: "#6c757d",
  [EventType.CHECKED_IN]: "#52c41a",
  [EventType.CHECKED_OUT]: "#1677ff",
  [EventType.MARKED_ABSENT]: "#6f42c1",
};

// 'inherit' | 'grey' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',

export const TIMELINE_COLORS_2: Record<EventType, string> = {
  [EventType.NOTE]: "warning",
  [EventType.BATHROOM]: "warning",
  [EventType.NAP]: "grey",
  [EventType.FOOD]: "grey",
  [EventType.ACTIVITY]: "grey",
  [EventType.CHECKED_IN]: "success",
  [EventType.CHECKED_OUT]: "primary",
  [EventType.MARKED_ABSENT]: "secondary",
};

export const TIMELINE_ICONS: Record<EventType, JSX.Element> = {
  [EventType.NOTE]: <FontAwesomeIcon icon={faComment} />,
  [EventType.BATHROOM]: <FontAwesomeIcon icon={faToilet} />,
  [EventType.NAP]: <FontAwesomeIcon icon={faBed} />,
  [EventType.FOOD]: <FontAwesomeIcon icon={faCookieBite} />,
  [EventType.ACTIVITY]: <FontAwesomeIcon icon={faPersonRunning} />,
  [EventType.CHECKED_IN]: <FontAwesomeIcon icon={faFaceSmile} />,
  [EventType.CHECKED_OUT]: <FontAwesomeIcon icon={faPersonWalkingArrowRight} />,
  [EventType.MARKED_ABSENT]: <FontAwesomeIcon icon={faGhost} />,
};

const PRIMARY_EVENT_TYPES = [
  EventType.CHECKED_IN,
  EventType.CHECKED_OUT,
  EventType.MARKED_ABSENT,
  EventType.NOTE,
];

const FOOD_EVENT_TYPE_TEXT: Record<FoodEventType, string> = {
  [FoodEventType.AM_SNACK]: "AM snack",
  [FoodEventType.BREAKFAST]: "breakfast",
  [FoodEventType.LUNCH]: "lunch",
  [FoodEventType.PM_SNACK]: "PM snack",
  [FoodEventType.DINNER]: "dinner",
};

const StudentHeader = styled.div`
  display: flex;
  gap: 16px;
`;

const StudentDetails = styled.div`
  display: flex;
  border-bottom: 2px solid #ccccff;
  flex-direction: column;
  padding-bottom: 16px;
  gap: 16px;

  .ant-typography {
    margin: 0;
  }
`;

const StyledTimeline = styled(Timeline)`
  max-width: 600px;
  margin: 12px 0;

  .ant-timeline-item-head-custom {
    border-radius: 50%;
    padding: 8px;
    border: 1px solid #ccccff;
  }
`;

const StyledTimelineItem = styled.div`
  margin: 16px 0;
`;

const EventCard = styled(card)`
  margin-bottom: 24px;

  .ant-card-body {
    background-color: #fff;
  }
`;

const EventTagDescriptionContainer = styled.div`
  margin-left: 8px;
`;

const StyledAlert = styled(Alert)``;

interface EventWithDescription extends Event {
  description: string;
}

interface StudentWithDetails extends Student {
  parents: Parent[];
}

const StudentComponent = () => {
  const { fetchWithToken } = useAuth();
  const navigate = useNavigate();
  const { studentId } = useParams();
  const classrooms = useRecoilValue(classroomsState);
  const [text, setText] = useState<string | undefined>();
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [eventsLoading, setEventsLoading] = useState(false);
  const [studentWithDetails, setStudentWithDetails] =
    useState<StudentWithDetails>();
  const [events, setEvents] = useState<EventWithDescription[]>([]);
  const [assignedClassroom, setAssignedClassroom] = useState<
    Classroom | undefined
  >();

  const [groupedTimelineEvents, setGroupedTimelineEvents] = useState<
    Map<string, TimelineItemProps[]>
  >(new Map());
  const [groupedEventSummaries, setGroupedEventSummaries] = useState<
    Map<string, string>
  >(new Map());

  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [eventModalSelectedType, setEventModalSelectedType] = useState<
    EventType | undefined
  >();

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        setDetailsLoading(true);
        const studentResponse = (await fetchWithToken({
          url: `/student/${studentId}/details`,
          method: "GET",
        })) as StudentGetDetailsResponse | undefined;

        if (studentResponse) {
          setStudentWithDetails({
            ...studentResponse.student,
            parents: studentResponse.parents,
          });
        }
      } catch (error) {
        console.log(error);
      }

      setDetailsLoading(false);
    };

    const fetchStudentEvents = async () => {
      try {
        setDetailsLoading(true);
        const studentResponse = (await fetchWithToken({
          url: `/student/${studentId}/events`,
          method: "GET",
        })) as StudentGetEventsResponse | undefined;

        if (studentResponse) {
          const eventsWithDescriptions = studentResponse.events.map((event) => {
            return {
              ...event,
              description: getHumanReadableEnum(event.type),
            };
          });

          setEvents(eventsWithDescriptions);

          const groupedSummaries = studentResponse.eventSummaries.reduce(
            (groups, summary) => {
              const formattedDate = new Date(
                summary.createdAt
              ).toLocaleDateString();

              groups.set(formattedDate, summary.text);

              return groups;
            },
            new Map<string, string>()
          );

          setGroupedEventSummaries(groupedSummaries);
        }
      } catch (error) {
        console.log(error);
      }

      setDetailsLoading(false);
    };

    if (studentId !== undefined) {
      fetchStudentEvents();
      fetchStudentDetails();
    }
  }, [fetchWithToken, studentId]);

  useEffect(() => {
    const assignedClassroom = classrooms.find(
      (classroom) => classroom.id === studentWithDetails?.classroomId
    );
    setAssignedClassroom(assignedClassroom);
  }, [studentWithDetails, classrooms]);

  useEffect(() => {
    const handleTimelineRefresh = (updatedEvents: EventWithDescription[]) => {
      const groupedEvents = updatedEvents.reduce((groups, event) => {
        const formattedDate = new Date(event.createdAt).toLocaleDateString();

        const existingEventsByDate = groups.get(formattedDate);

        const newEvent = {
          key: event.id,
          color: TIMELINE_COLORS[event.type],
          dot: TIMELINE_ICONS[event.type],
          children: (
            <Card style={{ marginLeft: 12 }}>
              <Typography.Text>
                {new Date(event.createdAt).toLocaleTimeString()}
              </Typography.Text>
              <Typography.Title level={5}>{event.description}</Typography.Title>
              <Typography.Text>{event.messages[0]?.text}</Typography.Text>
              {event.type === EventType.FOOD &&
                event.amountCompleted !== null &&
                event.foodEventType && (
                  <Typography.Text>
                    Ate{" "}
                    <Typography.Text strong>
                      {AmountCompletedText[event.amountCompleted].toLowerCase()}{" "}
                    </Typography.Text>
                    of{" "}
                    <Typography.Text strong>
                      {FOOD_EVENT_TYPE_TEXT[event.foodEventType]}
                    </Typography.Text>
                  </Typography.Text>
                )}
            </Card>
          ),
        };

        if (existingEventsByDate) {
          groups.set(formattedDate, [...existingEventsByDate, newEvent]);
        } else {
          groups.set(formattedDate, [newEvent]);
        }

        return groups;
      }, new Map<string, TimelineItemProps[]>());

      setGroupedTimelineEvents(groupedEvents);
    };

    handleTimelineRefresh(events);
  }, [events]);

  // TODO: Migrate to global state instead of re-fetch
  if (!studentWithDetails) {
    return (
      <>
        <SpinnerComponent />
      </>
    );
  }

  return (
    <Layout style={{ margin: 16, backgroundColor: "transparent" }}>
      <Content
        style={{
          margin: "24px 16px 0",
          overflow: "initial",
        }}
      >
        <StudentHeader>
          <div>
            <UserAvatarComponent user={studentWithDetails} size="large" />
          </div>
          <div>
            <Typography.Title>
              {studentWithDetails?.firstName} {studentWithDetails?.lastName}
            </Typography.Title>
          </div>
        </StudentHeader>
        <StudentDetails>
          <Flex gap={12} align="center">
            {assignedClassroom && (
              <Flex gap={12}>
                <Typography.Title level={5}>
                  Classroom: {assignedClassroom?.name}
                </Typography.Title>
                <AttendanceTag
                  student={studentWithDetails}
                  handleOnStatusChange={async (updatedStudent: Student) => {}}
                />
              </Flex>
            )}
          </Flex>

          {studentWithDetails.parents.length > 0 && (
            <Flex vertical>
              <Typography.Title level={5}>
                Parent Contact Info:
              </Typography.Title>
              {studentWithDetails.parents.map((parent) => (
                <Flex vertical>
                  {parent.firstName && (
                    <Typography.Text>
                      {parent.firstName} {parent.lastName}
                    </Typography.Text>
                  )}
                  <Typography.Text>{parent.email}</Typography.Text>
                </Flex>
              ))}
            </Flex>
          )}
        </StudentDetails>

        {Array.from(groupedTimelineEvents.entries()).length === 0 && (
          <Empty
            style={{ marginTop: 40 }}
            image={<FontAwesomeIcon icon={faChildReaching} />}
            imageStyle={{ height: 60 }}
            description={
              <Typography.Text>
                We'll show this student's history here once they've checked in
                for the first time.
              </Typography.Text>
            }
          />
        )}
        {Array.from(groupedTimelineEvents.entries()).length > 0 && (
          <Collapse
            bordered={false}
            defaultActiveKey={Array.from(groupedTimelineEvents.keys())[0]}
          >
            {Array.from(groupedTimelineEvents.entries()).map(
              ([date, events], index) => (
                <Panel
                  header={
                    <Flex vertical>
                      <Typography.Title level={5}>{date}</Typography.Title>
                      <Typography.Paragraph>
                        {groupedEventSummaries.get(date)}
                      </Typography.Paragraph>
                    </Flex>
                  }
                  key={date}
                >
                  <StyledTimeline items={events} />
                </Panel>
              )
            )}
          </Collapse>
        )}
      </Content>
      {studentWithDetails && eventModalSelectedType && (
        <LoggerModal
          studentsToLog={[studentWithDetails]}
          eventType={eventModalSelectedType}
          open={eventModalOpen}
          onOk={() => setEventModalOpen(false)}
          onCancel={() => setEventModalOpen(false)}
        />
      )}
    </Layout>
  );
};

export default StudentComponent;
