import {
  Button,
  Flex,
  Form,
  Input,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useAuth } from "../../../context/auth-context";
import { useNavigate } from "react-router-dom";
import { OnboardingStep } from "../../../types/user";

type FormFieldType = {
  users: {
    email: string;
  }[];
};

const SchoolAdminOnboardingStep1Component = () => {
  const { fetchWithToken } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleNextStep = async () => {
    setLoading(true);
    try {
      await fetchWithToken({
        url: `/onboarding`,
        method: "POST",
        body: {
          updatedStep: `STEP_${OnboardingStep.STEP_2}`,
        },
      });

      navigate("/onboarding/2");
    } catch (error) {
      notification.error({ message: "Error moving to next step" });
    }

    setLoading(false);
  };

  const handleCompleteStep1 = async (values: FormFieldType) => {
    setLoading(true);

    try {
      const response = await fetchWithToken({
        url: "/teacher/create-many",
        method: "POST",
        body: values.users,
      });

      if (response) {
        setSuccess(true);
        handleNextStep();
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  return (
    <Flex vertical style={{ textAlign: "center", marginTop: 40 }}>
      <Typography.Title level={2}>Complete your school setup</Typography.Title>
      <Typography.Text>
        Invite other teachers below to join your school.
      </Typography.Text>

      <Flex justify="center">
        <Spin spinning={loading}>
          <Form
            onFinish={handleCompleteStep1}
            style={{ maxWidth: 600, marginTop: 20 }}
            autoComplete="off"
            initialValues={{ users: [{}] }}
          >
            <Form.List name="users">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "email"]}
                        rules={[
                          { required: true, message: "Missing last name" },
                        ]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Invite Additional Teacher
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Flex justify="space-between">
              <Button type="primary" htmlType="submit">
                Send Invites
              </Button>
              <Button type="default" onClick={() => handleNextStep()}>
                Skip
              </Button>
            </Flex>
          </Form>
        </Spin>
      </Flex>
    </Flex>
  );
};

export default SchoolAdminOnboardingStep1Component;
