export enum UserRoleType {
  SUPER_ADMIN = "SUPER_ADMIN",
  TEACHER = "TEACHER",
  SCHOOL_ADMIN = "SCHOOL_ADMIN",
  PARENT = "PARENT",
  TOOLS_ONLY = "TOOLS_ONLY",
}

export interface UserRole {
  type: UserRoleType;
  schoolId?: number;
}

export enum OnboardingStep {
  STEP_1 = 1,
  STEP_2 = 2,
  STEP_3 = 3,
  STEP_4 = 4,
}

enum OnboardingType {
  SCHOOL,
  TEACHER,
  PARENT,
}
export interface UserOnboarding {
  complete: boolean;
  step: OnboardingStep;
  type: OnboardingType;
}

export interface GetCurrentUserResponse {
  id: number;
  firstName: number;
  lastName: number;
  email: string;
  roles: UserRole[];
  onboarding: UserOnboarding[];
}

export interface UserAuthResponse {
  accessToken?: string;
}

export interface UserAcceptInviteRequest {
  password: string;
  inviteCode: string;
}

export enum UserAcceptInviteErrorType {
  INVALID_TOKEN = "INVALID_TOKEN",
  INVITE_ALREADY_ACCEPTED = "INVITE_ALREADY_ACCEPTED",
}

export interface UserAcceptInviteResponse {
  accessToken?: string;
  acceptInviteSuccess: boolean;
  acceptInviteError?: UserAcceptInviteErrorType;
}
