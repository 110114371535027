import { Spin, SpinProps } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const SpinnerComponent = (props: SpinProps) => {
  return (
    <Spin
      indicator={<FontAwesomeIcon icon={faCircleNotch} spin />}
      size="large"
      {...props}
    />
  );
};

export default SpinnerComponent;
