import { selector } from "recoil";
import { utilFetchWithToken } from "../util";
import { accessTokenState, userRoleState } from "./auth";
import { SchoolFindOneResponse } from "../types/school";
import { UserRoleType } from "../types/user";

const schoolIdState = selector<number | undefined>({
  key: "schoolIdState",
  get: ({ get }) => {
    const userRole = get(userRoleState);
    return userRole?.schoolId;
  },
});

export const schoolState = selector<SchoolFindOneResponse | undefined>({
  key: "schoolState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const userRole = get(userRoleState);
    const schoolId = get(schoolIdState);

    if (
      userRole?.type !== UserRoleType.SUPER_ADMIN &&
      accessToken &&
      schoolId !== null
    ) {
      const response = (await utilFetchWithToken({
        accessToken,
        url: `/school/${schoolId}`,
        method: "GET",
      })) as SchoolFindOneResponse | undefined;
      return response;
    }
  },
});
