/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { Button, Typography, Flex, Card, Image } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ChatSessionType } from "../../types/chat-session";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import {
  activityPublishedContentState,
  allPublishedContentState,
  coloringSheetPublishedContentState,
  songPublishedContentState,
  storyPublishedContentState,
} from "../../states/published-content";
import { PublishedContent } from "../../types/published-content";
import {
  BUTTON_TITLE_BY_TYPE,
  LIST_PAGE_SUBTITLE_BY_TYPE,
  LIST_PAGE_TITLE_BY_TYPE,
} from "./util";

const { Title, Text } = Typography;

const Container = styled.div`
  @media (max-width: 768px) {
    margin: 24px;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledCard = styled(Card)`
  cursor: pointer;
  width: calc(33% - 20px);

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PublishedContentList = () => {
  const navigate = useNavigate();
  const { lowerCaseChatSessionType } = useParams<{
    lowerCaseChatSessionType: string;
  }>();

  const chatSessionType = lowerCaseChatSessionType
    ?.toUpperCase()
    .replace("-", "_") as ChatSessionType;
  const activityState = useRecoilValue(activityPublishedContentState);
  const storyState = useRecoilValue(storyPublishedContentState);
  const songState = useRecoilValue(songPublishedContentState);
  const coloringSheetState = useRecoilValue(coloringSheetPublishedContentState);

  const refreshPublishedContent = useRecoilRefresher_UNSTABLE(
    allPublishedContentState
  );

  useEffect(() => {
    refreshPublishedContent();
  }, [refreshPublishedContent]);

  let stateToUse: PublishedContent[] = [];

  switch (chatSessionType) {
    case ChatSessionType.ACTIVITY:
      stateToUse = activityState;
      break;
    case ChatSessionType.STORY:
      stateToUse = storyState;
      break;
    case ChatSessionType.SONG:
      stateToUse = songState;
      break;
    case ChatSessionType.COLORING_SHEET:
      stateToUse = coloringSheetState;
      break;
  }

  return (
    <Container>
      <Title level={3} style={{ marginBottom: 0 }}>
        {LIST_PAGE_TITLE_BY_TYPE[chatSessionType]}
      </Title>
      <Flex justify="space-between" align="center">
        <Text type="secondary">
          {LIST_PAGE_SUBTITLE_BY_TYPE[chatSessionType]}
        </Text>
        <Button
          type="primary"
          onClick={() => navigate(`/tools/${lowerCaseChatSessionType}/new`)}
          style={{ margin: "20px 0" }}
        >
          New {BUTTON_TITLE_BY_TYPE[chatSessionType]}
        </Button>
      </Flex>
      <ListContainer>
        {stateToUse.map((publishedContent) => (
          <StyledCard
            key={publishedContent.id}
            onClick={() =>
              navigate(
                `/tools/${lowerCaseChatSessionType}/${publishedContent.id}`
              )
            }
            cover={
              publishedContent.attachmentUrl ? (
                <Image
                  alt="coloring sheet cover"
                  src={publishedContent.attachmentUrl}
                  style={{
                    height: "200px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  preview
                />
              ) : undefined
            }
          >
            <Title level={4}>{publishedContent.title}</Title>
            <Text ellipsis>{publishedContent.text}</Text>
          </StyledCard>
        ))}
      </ListContainer>
    </Container>
  );
};

export default PublishedContentList;
