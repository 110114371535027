import {
  createContext,
  useContext,
  useMemo,
  ReactNode,
  useEffect,
} from "react";
import { useRecoilState } from "recoil";
import { accessTokenState, currentUserState } from "../states/auth";
import { GetCurrentUserResponse } from "../types/user";
import { useNavigate } from "react-router-dom";
import {
  FetchWithTokenProps,
  getAuthCookie,
  removeAuthCookie,
  utilFetchWithToken,
} from "../util";

interface AuthContextType {
  accessToken?: string;
  currentUser?: GetCurrentUserResponse;
  fetchWithToken: (props: FetchWithTokenProps) => Promise<any>;
  handleUserLogout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  fetchWithToken: async () => {},
  handleUserLogout: () => {},
});

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  useEffect(() => {
    const storedAccessToken = getAuthCookie();

    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
    }

    if (!accessToken && !storedAccessToken) {
      navigate("/sign-in");
    }
  }, [accessToken, setAccessToken, navigate]);

  useEffect(() => {
    const fetchCurrentUser = async (accessToken: string) => {
      const response = (await utilFetchWithToken({
        url: "/users/me",
        method: "GET",
        accessToken,
      })) as GetCurrentUserResponse;

      setCurrentUser(response);
    };

    if (accessToken) {
      fetchCurrentUser(accessToken);
    }
  }, [accessToken, setCurrentUser]);

  const value = useMemo(() => {
    const fetchWithToken = async (props: FetchWithTokenProps) => {
      if (accessToken) {
        return await utilFetchWithToken({
          ...props,
          accessToken,
        });
      }
    };

    const handleUserLogout = () => {
      removeAuthCookie();
      setCurrentUser(undefined);
      setAccessToken(undefined);
    };

    return {
      accessToken,
      currentUser,
      fetchWithToken,
      handleUserLogout,
    };
  }, [accessToken, currentUser, setAccessToken, setCurrentUser]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
