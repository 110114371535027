import { selector } from "recoil";
import { utilFetchWithToken } from "../util";
import { accessTokenState, userRoleState } from "./auth";
import {
  ClassroomFindManyWithStudentsResponse,
  ClassroomFindOneWithoutStatistics,
} from "../types/school";
import { schoolState } from "./school";
import { UserRoleType } from "../types/user";

export const classroomsState = selector<ClassroomFindOneWithoutStatistics[]>({
  key: "classroomsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const userRole = get(userRoleState);
    const school = get(schoolState);

    if (accessToken && school && userRole?.type !== UserRoleType.PARENT) {
      const response = (await utilFetchWithToken({
        accessToken,
        url: `/school/${school.id}/classrooms`,
        method: "GET",
      })) as ClassroomFindManyWithStudentsResponse | undefined;

      return response?.classrooms ?? [];
    }

    return [];
  },
});
