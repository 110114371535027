/** @jsxImportSource @emotion/react */
import {
  Flex,
  Form,
  Input,
  Mentions,
  Modal,
  Select,
  Slider,
  Tag,
  Typography,
  notification,
} from "antd";
import {
  EventCreateOneRequest,
  EventType,
  FoodEventType,
} from "../../types/event";
import { Student } from "../../types/student";
import { useAuth } from "../../context/auth-context";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 95%;
  margin: 0 auto;
`;

export const EVENT_BUTTON_TEXT: Record<EventType, string> = {
  [EventType.NOTE]: "",
  [EventType.BATHROOM]: "Bathroom",
  [EventType.NAP]: "Nap",
  [EventType.FOOD]: "Food",
  [EventType.ACTIVITY]: "Activity",
  [EventType.CHECKED_IN]: "",
  [EventType.CHECKED_OUT]: "",
  [EventType.MARKED_ABSENT]: "",
};

export const AmountCompletedText: Record<number, string> = {
  0: "None",
  1: "Some",
  2: "Half",
  3: "Most",
  4: "All",
};

const LogEventForm: React.FC<{
  eventType: EventType;
  studentsToLog: Student[];
  noteText: string;
  setNoteText: (updatedText: string) => void;
  sliderValue: number;
  setSliderValue: (updatedValue: number) => void;
  foodEventType?: FoodEventType;
  setFoodEventType: (updatedValue: FoodEventType) => void;
}> = ({
  eventType,
  studentsToLog,
  sliderValue,
  setSliderValue,
  noteText,
  setNoteText,
  foodEventType,
  setFoodEventType,
}) => {
  const [selectedStudents, setSelectedStudents] = useState([...studentsToLog]);

  const isFoodEvent = eventType === EventType.FOOD;

  return (
    <Flex vertical gap={10}>
      {isFoodEvent && (
        <>
          <Typography.Text strong>Meal Type:</Typography.Text>
          <Select
            onChange={setFoodEventType}
            defaultValue={FoodEventType.BREAKFAST}
            style={{ maxWidth: 200 }}
            options={[
              {
                label: "Breakfast",
                value: FoodEventType.BREAKFAST,
              },
              {
                label: "AM Snack",
                value: FoodEventType.AM_SNACK,
              },
              {
                label: "Lunch",
                value: FoodEventType.LUNCH,
              },
              {
                label: "PM Snack",
                value: FoodEventType.PM_SNACK,
              },
              {
                label: "Dinner",
                value: FoodEventType.DINNER,
              },
            ]}
          />
          <Typography.Text strong>Amount:</Typography.Text>
          <Container>
            <Slider
              min={0}
              max={4}
              marks={AmountCompletedText}
              value={sliderValue}
              onChange={setSliderValue}
            />
          </Container>
        </>
      )}
      <Input.TextArea
        rows={4}
        placeholder="Note"
        value={noteText}
        onChange={(event) => setNoteText(event.currentTarget.value)}
      />
    </Flex>
  );
};

export default LogEventForm;
