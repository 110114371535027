/** @jsxImportSource @emotion/react */
import { Card, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import { Col } from "react-bootstrap";
import { ClassroomFindOneWithoutStatistics } from "../../../types/school";
import { Student } from "../../../types/student";
import AvatarListComponent from "../../classroom/avatar-list";

const ClassroomListComponent = ({
  classrooms,
  presentStudents,
}: {
  classrooms: ClassroomFindOneWithoutStatistics[];
  presentStudents: Map<number, Student[]>;
}) => {
  const navigate = useNavigate();

  return (
    <>
      {classrooms?.map((classroom) => (
        <Col
          sm={4}
          css={css`
            align-items: center;
          `}
        >
          <Card
            style={{
              flex: "0 1 calc(33% - 10px)",
              padding: 0,
              cursor: "pointer",
              height: "100%",
            }}
            onClick={() => navigate(`/classroom/${classroom?.id}`)}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 8px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  gap: 16px;
                  align-items: center;
                `}
              >
                <Typography.Title level={3}>{classroom.name}</Typography.Title>
              </div>
              {presentStudents.get(classroom.id) &&
              presentStudents.get(classroom.id)!.length > 0 ? (
                <>
                  <Typography.Text>Present Students</Typography.Text>
                  <AvatarListComponent
                    users={presentStudents.get(classroom.id) ?? []}
                  />
                </>
              ) : (
                <Typography.Text>No students present</Typography.Text>
              )}
            </div>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default ClassroomListComponent;
