/** @jsxImportSource @emotion/react */
import { Form, Input, Modal, Typography } from "antd";
import { useState } from "react";
import { useAuth } from "../../../context/auth-context";

type FormFieldType = {
  email: string;
  firstName: string;
  lastName: string;
  schoolName: string;
};

const SchoolInviteModal: React.FC<{
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}> = ({ open, onOk, onCancel }) => {
  const { fetchWithToken } = useAuth();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [inviteCode, setInviteCode] = useState<string | undefined>();

  const handleCreateInvite = async () => {
    setLoading(true);

    try {
      const values: {
        firstName: string;
        lastName: string;
        email: string;
        schoolName: string;
      } = await form.validateFields();

      console.log(values);

      const response = (await fetchWithToken({
        url: "/super-admin/create-invite",
        method: "POST",
        body: values,
      })) as {
        code: string;
      };

      if (response) {
        setInviteCode(response.code);
      }
    } catch (error) {
      console.log("validation failed", error);
    }

    setLoading(false);
  };

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Modal
      open={open}
      okType="primary"
      okText="Send Invite"
      onOk={() => handleCreateInvite()}
      onCancel={() => handleOnCancel()}
      title="Invite School"
      confirmLoading={loading}
    >
      <Typography.Text>
        Enter the school adminstrator's information below.{" "}
      </Typography.Text>
      <Typography.Text type="warning">
        This will send an email to the school admin.
      </Typography.Text>
      {inviteCode && (
        <Typography.Text>
          Invite email sent. Invite code: {inviteCode}
        </Typography.Text>
      )}

      <Form
        form={form}
        preserve={false}
        name="basic"
        labelAlign="left"
        labelCol={{ span: 9 }}
        onFinishFailed={(error) => console.log(error)}
        autoComplete="off"
      >
        <Form.Item<FormFieldType>
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "First Name is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<FormFieldType>
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: "Last Name is required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FormFieldType>
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<FormFieldType>
          label="School Name"
          name="schoolName"
          rules={[{ required: true, message: "School name is required" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SchoolInviteModal;
