import { ChatSessionType } from "../../../types/chat-session";

export const URL_TO_TYPE: Record<string, ChatSessionType> = {
  "/tools/activity": ChatSessionType.ACTIVITY,
  "/tools/story": ChatSessionType.STORY,
  "/tools/song": ChatSessionType.SONG,
};

// Title that appears on the list page
export const LIST_PAGE_TITLE_BY_TYPE: Record<ChatSessionType, string> = {
  [ChatSessionType.ACTIVITY]: "All Activities",
  [ChatSessionType.CHAT]: "",
  [ChatSessionType.STUDENT_EVENT]: "",
  [ChatSessionType.STORY]: "All Stories",
  [ChatSessionType.SONG]: "All Songs",
  [ChatSessionType.COLORING_SHEET]: "All Coloring Sheets",
};

export const LIST_PAGE_SUBTITLE_BY_TYPE: Record<ChatSessionType, string> = {
  [ChatSessionType.ACTIVITY]: "Activities created by you",
  [ChatSessionType.CHAT]: "",
  [ChatSessionType.STUDENT_EVENT]: "",
  [ChatSessionType.STORY]: "Stories created by you",
  [ChatSessionType.SONG]: "Songs created by you",
  [ChatSessionType.COLORING_SHEET]: "Coloring Sheets created by you",
};

// Text that appears on all buttons containing the type
export const BUTTON_TITLE_BY_TYPE: Record<ChatSessionType, string> = {
  [ChatSessionType.ACTIVITY]: "Activity",
  [ChatSessionType.STORY]: "Story",
  [ChatSessionType.SONG]: "Song",
  [ChatSessionType.COLORING_SHEET]: "Coloring Sheet",
  [ChatSessionType.STUDENT_EVENT]: "",
  [ChatSessionType.CHAT]: "",
};

// Title that appears on the create page
export const CREATE_PAGE_TITLE_BY_TYPE: Record<ChatSessionType, string> = {
  [ChatSessionType.ACTIVITY]: "Create an Activity",
  [ChatSessionType.STORY]: "Create a Story",
  [ChatSessionType.SONG]: "Create a Song",
  [ChatSessionType.COLORING_SHEET]: "Create a Coloring Sheet",
  [ChatSessionType.STUDENT_EVENT]: "",
  [ChatSessionType.CHAT]: "",
};

// Subtitle that appears on the create page
export const CREATE_PAGE_SUBTITLE_BY_TYPE: Record<ChatSessionType, string> = {
  [ChatSessionType.ACTIVITY]: "Ask me to create an activity for your students!",
  [ChatSessionType.STORY]: "Ask me to create a story for your students!",
  [ChatSessionType.SONG]: "Ask me to create a song for your students!",
  [ChatSessionType.COLORING_SHEET]:
    "Ask me to create a coloring sheet for your students!",
  [ChatSessionType.STUDENT_EVENT]: "",
  [ChatSessionType.CHAT]: "",
};

// Placeholder text that appears on the input field
export const CREATE_PAGE_PLACEHOLDER_TEXT_BY_TYPE: Record<
  ChatSessionType,
  string
> = {
  [ChatSessionType.ACTIVITY]: "Circle time farm animal activity",
  [ChatSessionType.STORY]: "Once upon a time...",
  [ChatSessionType.SONG]: "Sing a song with your students!",
  [ChatSessionType.COLORING_SHEET]: "A coloring sheet about animals",
  [ChatSessionType.STUDENT_EVENT]: "",
  [ChatSessionType.CHAT]: "",
};
