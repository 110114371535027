/** @jsxImportSource @emotion/react */
import { Button, Divider, Flex, Layout, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import { Container } from "react-bootstrap";
import { useAuth } from "../../../context/auth-context";
import { useRecoilValue } from "recoil";
import { currentUserNameWithGreetingState } from "../../../states/auth";
import SchoolInviteModal from "./school-invite-modal";
import { SuperAdminSchoolFindManyResponse } from "../../../types/school";

const SuperAdminHomeComponent = () => {
  const navigate = useNavigate();
  const { fetchWithToken, currentUser } = useAuth();
  const currentUserNameWithGreeting = useRecoilValue(
    currentUserNameWithGreetingState
  );
  const [schools, setSchools] = useState<
    SuperAdminSchoolFindManyResponse["schools"]
  >([]);

  const [loading, setLoading] = useState(false);
  const [schoolInviteModalOpen, setSchoolInviteModalOpen] = useState(false);

  useEffect(() => {
    const fetchSchools = async () => {
      const response = (await fetchWithToken({
        url: "/school",
        method: "GET",
      })) as SuperAdminSchoolFindManyResponse | undefined;

      if (response) {
        setSchools(response.schools);
      }
    };

    fetchSchools();
  }, [fetchWithToken]);

  return (
    <Layout style={{ margin: 16, backgroundColor: "transparent" }}>
      <Content
        style={{
          margin: "24px 16px 0",
          overflow: "initial",
        }}
      >
        <Typography.Title level={2}>
          {currentUserNameWithGreeting}
        </Typography.Title>
        <Divider />
        <Container fluid>
          <Flex justify="space-between">
            <Typography.Title level={3}>Schools</Typography.Title>
            <Button
              type="primary"
              onClick={() => setSchoolInviteModalOpen(true)}
            >
              Invite School
            </Button>
          </Flex>
          <Table
            columns={[
              {
                title: "Name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
              },
            ]}
            dataSource={schools}
          />
        </Container>
        <SchoolInviteModal
          open={schoolInviteModalOpen}
          onOk={() => setSchoolInviteModalOpen(false)}
          onCancel={() => setSchoolInviteModalOpen(false)}
        />
      </Content>
    </Layout>
  );
};

export default SuperAdminHomeComponent;
