/** @jsxImportSource @emotion/react */
import { Button, Layout, Typography, message, theme } from "antd";
import {
  Classroom,
  ClassroomFindOneResponse,
  ClassroomStatistics,
} from "../../types/classroom";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import { Student } from "../../types/student";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { TeacherFindOneResponse } from "../../types/teacher";
import { useAuth } from "../../context/auth-context";
import StudentTable from "./student-table";
import { useRecoilValue } from "recoil";
import { classroomsState } from "../../states/classrooms";
import AiEventWizard from "./ai-event-wizard";
import AiToolsHome from "../co-teacher";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FixedHeader = styled.div``;

const ScrollingContainer = styled(Container)``;

const ClassroomDetails = styled.div`
  display: flex;
  gap: 32px;
  //border-bottom: 2px solid rgb(86, 86, 86, 0.5);
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const StudentContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const StudentName = styled.div`
  display: flex;
  cursor: pointer;
  gap: 16px;
  align-items: center;

  .ant-typography {
    margin: 0;
  }

  @media (min-width: 767px) {
    padding-right: 20px;
  }
`;

const StudentActions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;

  .ant-tag {
    margin: auto 0;
  }
`;

const ClassroomComponent = () => {
  const navigate = useNavigate();
  const { fetchWithToken } = useAuth();
  const { classroomId } = useParams();

  const [messageApi, contextHolder] = message.useMessage();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const allClassrooms = useRecoilValue(classroomsState);

  const [detailsLoading, setDetailsLoading] = useState(false);
  const [classroom, setClassroom] = useState<Classroom>();
  const [students, setStudents] = useState<Student[]>([]);
  const [assignedTeachers, setAssignedTeachers] = useState<
    TeacherFindOneResponse[]
  >([]);
  const [classroomStatistics, setClassroomStatistics] = useState<
    ClassroomStatistics | undefined
  >();

  const [selectedStudent, setSelectedStudent] = useState<Student | undefined>();
  const [showAiLogWizard, setShowAiLogWizard] = useState(false);
  const [showCoTeacherWizard, setShowCoTeacherWizard] = useState(false);

  const [buttonLoadingStatus, setButtonLoadingStatus] = useState<
    Map<string, boolean>
  >(new Map());

  useEffect(() => {
    const selectedClassroom = allClassrooms.find(
      (classroom) => classroom.id === Number(classroomId)
    );

    if (selectedClassroom) {
      setClassroom(selectedClassroom);
    }
  }, [allClassrooms, classroomId]);

  useEffect(() => {
    const fetchClassroom = async () => {
      try {
        setDetailsLoading(true);

        const classroomResponse = (await fetchWithToken({
          url: `/classroom/${classroomId}`,
          method: "GET",
        })) as ClassroomFindOneResponse | undefined;

        if (classroomResponse) {
          setClassroom(classroomResponse);
          setStudents(classroomResponse.students);
          setAssignedTeachers(classroomResponse.teachers);
          setClassroomStatistics(classroomResponse.statistics);
        }
      } catch (error) {
        console.log(error);
      }

      setDetailsLoading(false);
    };

    if (classroomId !== undefined) {
      fetchClassroom();
    }
  }, [classroomId, fetchWithToken]);

  const handleMagicAssistOnOk = () => {
    setShowAiLogWizard(false);

    messageApi.open({
      type: "success",
      content: "Highlights saved successfully",
    });
  };

  const handleShowAiScreen = (student: Student) => {
    setSelectedStudent(student);
    setShowAiLogWizard(true);
  };

  return (
    <>
      {contextHolder}
      {!showAiLogWizard && !showCoTeacherWizard && (
        <Layout style={{ margin: 16, backgroundColor: "transparent" }}>
          {contextHolder}
          <Content
            style={{
              margin: "24px 16px 0",
              overflow: "initial",
            }}
          >
            {/*
        <FixedHeader>
          <Typography.Title level={2}> {classroom?.name}</Typography.Title>
          {/*classroomStatistics && (
            <ClassroomDetails>
              <Statistic
                title="Present Students"
                value={classroomStatistics?.presentStudents}
              />
              <Statistic
                title="Teacher Ratio"
                value={classroomStatistics.teacherRatio}
              />
              <Statistic
                title="Total Assigned Students"
                value={classroomStatistics?.totalStudents}
              />
            </ClassroomDetails>
          )
        </FixedHeader>
    
        <DividerComponent />
            */}
            <Button
              shape="round"
              size="large"
              onClick={() => setShowCoTeacherWizard(true)}
              icon={<FontAwesomeIcon icon={faWandMagicSparkles} />}
            >
              Magic Assist
            </Button>
            {classroom && (
              <StudentTable
                title={
                  <Typography.Title level={5}>
                    Students Assigned to Classroom
                  </Typography.Title>
                }
                loading={detailsLoading}
                students={students}
                setStudents={setStudents}
                classroomId={classroom?.id}
                handleShowAiScreen={handleShowAiScreen}
              />
            )}
          </Content>
        </Layout>
      )}
      {showAiLogWizard && selectedStudent && (
        <AiEventWizard
          selectedStudentsToLog={[selectedStudent]}
          onOk={() => handleMagicAssistOnOk()}
          onCancel={() => setShowAiLogWizard(false)}
        />
      )}
    </>
  );
};

export default ClassroomComponent;
