/** @jsxImportSource @emotion/react */
import { Avatar, AvatarProps } from "antd";

const UserAvatarComponent = ({
  user,
  size,
}: {
  size?: AvatarProps["size"];
  user: {
    firstName?: string;
    lastName?: string;
  };
}) => {
  if (!user.firstName && !user.lastName) {
    return null;
  }

  const initials = `${user.firstName?.[0] || ""}${user.lastName?.[0] || ""}`;
  const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  return (
    <Avatar style={{ backgroundColor: color }} size={size}>
      {initials}
    </Avatar>
  );
};

export default UserAvatarComponent;
