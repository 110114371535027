import { useRecoilValue } from "recoil";
import { onboardingState, userRoleState } from "../../../states/auth";
import { useEffect } from "react";
import { Flex } from "antd";
import SpinnerComponent from "../../common/spinner";
import { UserRoleType } from "../../../types/user";
import SchoolAdminOnboardingStep2Component from "./school-admin";

const OnboardingStep2Component = () => {
  const userRole = useRecoilValue(userRoleState);
  const onboarding = useRecoilValue(onboardingState);

  useEffect(() => {}, [onboarding]);

  if (userRole?.type === UserRoleType.SCHOOL_ADMIN) {
    return <SchoolAdminOnboardingStep2Component />;
  }

  return (
    <Flex style={{ height: "100%" }} justify="center" align="center">
      <SpinnerComponent size="large" />
    </Flex>
  );
};

export default OnboardingStep2Component;
