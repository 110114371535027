import { DividerProps, Divider } from "antd";
import styled from "styled-components";

const StyledDivider = styled(Divider)`
  border-block-start: 2px solid #ccccff;
`;

const DividerComponent = (props: DividerProps) => {
  return <StyledDivider {...props} />;
};

export default DividerComponent;
